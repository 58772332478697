<template>
  <div class="chat-web-window">
    <message-window
      v-if="onlyMessage"
      :theme="theme"
      @close="closeChat"
    />
    <chat-window
      v-else
      platform="web-window"
    />
  </div>
</template>

<script>
import ChatWindow from '@components/chat-window'
import MessageWindow from '@components/message-window'
import { EventBus } from '@utils/common.js'
export default {
  name: 'ChatWeb',
  components: {
    ChatWindow,
    MessageWindow
  },
  data () {
    return {
      urlReferer: '',
      sourceEvent: null
    }
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    theme () {
      return this.chatConfig.webDetailInfoVO?.baseSetting?.themeColor || undefined
    },
    onlyMessage () {
      return this.$store.state.openSocket === false && this.chatConfig.leaveMessage?.isEnable
    }
  },
  methods: {
    closeChat () {
      const spmCloseChat = 'c0401.d0414'/** data-spm-desc: 聊天对话框-关闭留言窗 **/
      window.Monitor?.sendSPM(spmCloseChat)
      const message = JSON.stringify({ type: 'close' })
      EventBus.$emit('closeChatWindow', message)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-web-window{
  width: 100%;
  height: 100%;
}
</style>
