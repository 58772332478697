<template>
  <el-drawer
    class="drawer-message"
    :visible.sync="visible"
    :with-header="false"
    :modal="false"
    :close-on-press-escape="false"
    :wrapper-closable="false"
    :size="330"
  >
    <MessageWindow
      :theme="theme"
      @close="visible = false"
    />
  </el-drawer>
</template>

<script>
import MessageWindow from '@components/message-window'
export default {
  name: 'DrawerMessage',
  components: {
    MessageWindow
  },
  props: {
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-message{
  position: absolute;

  ::v-deep .el-drawer{
    background-color: transparent;
  }
}
</style>
