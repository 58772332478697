// 调度结果
export const SCHEDULE_RRESULT = {
  'SUCCESSFUL': 'SUCCESSFUL', // 调度成功,返回qw客服id
  'UNSUCCESSFUL': 'UNSUCCESSFUL', // 调度失败, 遇到了错误,
  'QUEUED': 'QUEUED', // 客服繁忙, 进入排队, 返回msg提示语
  'QUEUED_FULL': 'QUEUED_FULL', // 客服队列满了, 返回msg提示语
  'UNAVAILABLE': 'UNAVAILABLE', // 没有在线的客服
  'SUCCESSFUL_TRANSFER': 'SUCCESSFUL_TRANSFER', // 转接成功
  'SUCCESSFUL_FORCED_TRANSFER': 'SUCCESSFUL_FORCED_TRANSFER', // 抢接成功
  'IGNORED': 'IGNORED' // 调度被忽视
}

// 事件类型
export const EVENT_ACTIONS = {
  msg: 'msg', // 正常消息
  system: 'system', // 系统消息
  offline: '999', // 离线
  schedule: 'schedule', // 调度
  withdraw: 'withdraw', // 撤回
  'new-visitor': 'new-visitor', // 新访客
  'is-read': 'is-read', // 已读
  transfer: 'transfer', // 转接
  'close-session': 'close-session' // 结束会话
}

// 客服状态
export const RECEPTIONIST_STATUS = {
  online: 'online',
  offline: 'offline',
  beBusy: 'beBusy'
}

// 用户类型:
export const USER_TYPE = {
  customer: '0', // 客服
  client: '1' // 客户（游客）
}

// 文件类型
export const FILE_SUFFIX_TYPE = {
  '.jpg': 'image',
  '.jpeg': 'image',
  '.png': 'image',
  '.pdf': 'pdf',
  '.doc': 'doc',
  '.docx': 'doc',
  '.ppt': 'ppt',
  '.pptx': 'ppt',
  '.xls': 'excel',
  '.xlt': 'excel',
  '.xlsx': 'excel',
  '.txt': 'txt',
  '.mp3': 'audio',
  '.mp4': 'video',
  '.mov': 'video'
}

// 文档图标
export const DOC_ICONS = Object.freeze({
  'doc': require('@/assets/images/file/kf-doc.png'),
  'excel': require('@/assets/images/file/kf-excel.png'),
  'news': require('@/assets/images/file/kf-link.png'),
  'pdf': require('@/assets/images/file/kf-pdf.png'),
  'ppt': require('@/assets/images/file/kf-ppt.png'),
  'txt': require('@/assets/images/file/kf-txt.png'),
  'video': require('@/assets/images/file/kf-video.png'),
  'audio': require('@/assets/images/file/kf-audio.png')
})

// 文件 类型
export const DOC_TYPES = Object.freeze(['doc', 'pdf', 'ppt', 'excel', 'txt', 'video', 'audio'])

// 文件accept类型
export const FILE_ACCEPT_TYPE = {
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.pdf': 'application/pdf',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.xls': 'application/vnd.ms-excel',
  '.xlt': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.txt': 'text/plain',
  '.mp3': 'audio/mpeg',
  '.mp4': 'video/mp4',
  '.mov': 'video/quicktime'
}
