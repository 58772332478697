<template>
  <div class="ds-cloud-files-upload">
    <el-upload
      ref="uploader"
      action=""
      :accept="accept"
      :multiple="multiple"
      :before-upload="beforeUpload"
      :http-request="httpRequestUpload"
      :file-list="fileList"
      :show-file-list="showFileList"
      v-bind="$attrs"
    >
      <slot
        slot="default"
        name="default"
      />
      <slot
        slot="file"
        name="file"
      />
      <slot
        slot="trigger"
        name="trigger"
      />
      <slot
        slot="tip"
        name="tip"
      />
    </el-upload>
  </div>
</template>

<script>
import { getUploadSign } from '@/utils/common.js'
export default {
  name: 'CloudFilesUpload',
  props: {
    // * 获取oss-token的接口
    // ! 非必填，可不使用该参数，但必须在父组件里面绑定http-request函数，用于自定义获取oss-token
    // * 默认使用：营销团队的获取oss-token接口
    tokenApi: {
      type: Object,
      default () {
        return {
          // ! api必须为promise对象
          /* eslint-disable */
          api: null,
          params: {}
        }
      }
    },

    // * 上传多种类型文件，默认：空数组
    // * 非必填，可不使用该参数，不会影响功能
    uploadTypes: {
      type: Array,
      default: () => {
        return []
      }
    },

    // * 图片类型的相关限默认制
    // * 非必填，可不使用该参数，不会影响功能
    imageUploadLimit: {
      type: Object,
      default () {
        return { accept: '.jpg,.png,.jpeg', limitSize: 5 }
      }
    },

    // * pdf类型的相关限默认制,
    // * 非必填，可不使用该参数，不会影响功能
    pdfUploadLimit: {
      type: Object,
      default () {
        return { accept: '.pdf', limitSize: 20 }
      }
    },

    // * word类型的相关限默认制,
    // * 非必填，可不使用该参数，不会影响功能
    docUploadLimit: {
      type: Object,
      default () {
        return { accept: '.doc,.docx', limitSize: 20 }
      }
    },

    // * ppt类型的相关限默认制,
    // * 非必填，可不使用该参数，不会影响功能
    pptUploadLimit: {
      type: Object,
      default () {
        return { accept: '.ppt,.pptx', limitSize: 20 }
      }
    },

    // * excel类型的相关限默认制,
    // * 非必填，可不使用该参数，不会影响功能
    xlsUploadLimit: {
      type: Object,
      default () {
        return { accept: '.xls,.xlt,.xlsx', limitSize: 20 }
      }
    },

    // * 文本类型的相关限默认制,
    // * 非必填，可不使用该参数，不会影响功能
    txtUploadLimit: {
      type: Object,
      default () {
        return { accept: '.txt', limitSize: 20 }
      }
    },

    // * 音频类型的相关默认限制
    // * 非必填，可不使用该参数，不会影响功能
    audioUploadLimit: {
      type: Object,
      default () {
        return { accept: '.mp3', limitSize: 10 }
      }
    },

    // * 视频类型的相关默认限制
    // * 非必填，可不使用该参数，不会影响功能
    videoUploadLimit: {
      type: Object,
      default () {
        return { accept: '.mp4,.mov', limitSize: 50 }
      }
    },

    // * 接受上传的文件类型，默认：空
    accept: {
      type: String,
      default: ''
    },

  // * 接受上传的文件后缀类型，默认：空
    acceptType: {
      type: String,
      default: ''
    },

    // * 当文件类型不符合时，显示的错误提示文字
    noAcceptMsg: {
      type: String,
      default: ''
    },

    // * 是否支持多选文件，默认false，不支持
    multiple: {
      type: Boolean,
      default: false
    },

    // * 已上传的文件列表
    fileList: {
      type: Array,
      default () {
        return []
      }
    },

    // * 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: false
    },

    // * 上传文件的大小，默认5M以内
    limitSize: {
      type: Number,
      default: 5
    },

    // 限制上传总文件的信息
    limitTotalInfo: {
      type: Object,
      default: () => {
        return {
          limitTotalSize: 0, // 限制上传的文件总大小
          needLimit: false,  // 是否需要限制上传文件的总大小
          isReplace: false,  // 是否为替换文件操作
          replaceIndex: 0    // 替换的文件在fileList中的index
        }
      }
    }
  },
  computed: {
    // * 当前可上传文件的大小Map
    limitSizeMap() {
      let limitSizeMap = new Map()
      this.uploadTypes.forEach(type => {
        const limitItem = this[type + 'UploadLimit']
        const accepts = limitItem.accept.replace(/\s/ig, '').split(',')
        accepts.forEach(accept => {
          limitSizeMap.set(accept, limitItem.limitSize)
        })
      })
      return limitSizeMap
    }
  },
  methods: {
    // el-upload自带函数：上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传
    async beforeUpload (file) {
      // 限制文件总大小
      const isTotalSize = await this.checkTotalSize(file)
      if (!isTotalSize) return false
      // 验证上传文件的类型是否正确
      const isAccept = await this.checkAccept(file)
      if (!isAccept) return false
      // 验证上传文件的大小是否符合规范
      const isLimitSize = await this.checkLimitSize(file)
      if (!isLimitSize) return false
      // 调用父组件on-upload函数
      this.$emit('on-upload', { type: 'before', file })
    },

    // 限制文件总大小
    checkTotalSize (file) {
      return new Promise((resolve, reject) => {
        if (this.limitTotalInfo.needLimit) {
          let totalSize = file.size
          this.fileList.forEach(item => {
            totalSize += item.size
          })
          // 若当前是替换文件上传，则需要减去需要替换的文件大小
          if (this.limitTotalInfo.isReplace) {
            totalSize -= this.fileList[this.limitTotalInfo.replaceIndex]['size']
          }
          if (((totalSize / 1024 /1024).toFixed(1)) > this.limitTotalInfo.limitTotalSize) {
            const noAcceptMsg = `超出文件总大小（${this.limitTotalInfo.limitTotalSize}M）限制`
            this.$notify({type: 'warning', message: noAcceptMsg})
            // 调用父组件on-upload函数
            this.$emit('on-upload', {
              type: 'total-size-fail',
              result: { msg: noAcceptMsg },
              file: file
            })
            reject(new Error(noAcceptMsg))
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },

    // 验证上传文件的类型是否正确
    checkAccept (file) {
      return new Promise((resolve, reject) => {
        if (this.acceptType) {
          const suffix = this.getFileSuffix(file)
          const isAccept = this.acceptType.match(suffix)
          if (isAccept) resolve(true)
          else {
            const noAcceptMsg =
              this.noAcceptMsg ||
              `请上传 ${this.acceptType.substr(1).replaceAll(',.', '/')} 等格式`
            this.$notify({type: 'warning', message: noAcceptMsg})
            // 调用父组件on-upload函数
            this.$emit('on-upload', {
              type: 'accept-fail',
              result: { msg: noAcceptMsg },
              file: file
            })
            reject(new Error(noAcceptMsg))
          }
        } else resolve(true)
      })
    },

    // 验证上传文件的大小是否符合规范
    checkLimitSize (file) {
      return new Promise((resolve, reject) => {
        if (this.limitSizeMap.size) {
          const suffix = this.getFileSuffix(file)
          const currLimitSize = this.limitSizeMap.get(suffix)
          const isLimitSize = file.size / 1024 / 1024 <= currLimitSize
          if (isLimitSize) resolve(true)
          else {
            const errorMsg = `文件大小不能超过${currLimitSize}M`
            this.$notify({type: 'warning', message: errorMsg})
            // 调用父组件on-upload函数
            this.$emit('on-upload', {
              type: 'size-fail',
              result: { msg: errorMsg },
              file: file
            })
            reject(new Error(errorMsg))
          }
        } else resolve(true)
      })
    },

    // 获取上传文件的后缀名
    getFileSuffix (file) {
      const index = file.name.lastIndexOf('.')
      // 文件类型（就是后缀png、jpeg等等）
      const suffix = file.name.substr(index).toLocaleLowerCase()
      return suffix || ''
    },
    // el-upload自定义上传函数：
    async httpRequestUpload ({ file }) {
      if (this.tokenApi.api) {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('sign', getUploadSign())
        const params = formData
        try {
          const { data, success } = await this.tokenApi.api(params, {
            header: {
              'Content-Type': 'multipart/form-data',
            }
          })
          this.$emit('on-upload', success ? {
            type: 'success',
            file,
            result: {
              data
            }
          } : {
            type: 'fail'
          })
        } catch (error) {
          this.$emit('on-upload', {
            type: 'fail'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ds-cloud-files-upload {
  line-height: normal;
  // font-size: 0;
}
</style>
