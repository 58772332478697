/*
 * @Author: lihaitao
 * @Date: 2020-01-10 10:46:58
 * @Last Modified by: wangqs
 * @Last Modified time: 2020-05-15 16:11:31
 */
import { AxiosInstance, AxiosResponse, AxiosError } from 'axios'
import ErrorCode from './errorCode'
import LocalUser from '@/utils/common/LocalUser'

interface Config {
  instance: AxiosInstance
  toast: any
}
class ErrorCodeInterceptors {
  constructor (config: Config) {
    this.config = config
    this.init()
  }

  config: Config

  toastFlag: any

  init () {
    this.config.instance.interceptors.response.use((response: AxiosResponse) => {
      const { success, code, msg } = response.data
      const mapCode: string = ErrorCode[code]

      // 兼容老的错误提示方式，保留此段代码
      // 老的错误处理方式为后端返回false，并且返回code，前端进行错误提示
      if (!success && code) {
        const codeMsg: string = mapCode || msg
        this.toastError(codeMsg)
        if (code === 4001) {
          const user: any = LocalUser.getQWUser()
          const url = `${window.location.origin}${window.location.pathname}#/login?agentId=${user.agentId}&corpId=${user.corpId}`
          window.location.replace(url)
        }
      } else if (!success) {
        this.toastError(msg)
      }

      // 新的错误处理方式为，只要后端不报错，都返回true
      // 前端进行提示以及操作
      if (success) {
        // 如果在errorCode中定义了code，则直接进行提示
        if (mapCode) {
          this.toastError(mapCode)
        }
      }

      return response
    }, (error: AxiosError | Error) => {
      return Promise.reject(error)
    })
  }

  toastError (message: string) {
    if (this.toastFlag) return
    this.toastFlag = this.config.toast({
      type: 'danger',
      message,
      onClose: () => {
        this.toastFlag = null
      }
    })
  }

  getInterceptorsInstance () {
    return this.config.instance.interceptors.response
  }
}

export default ErrorCodeInterceptors
