<template>
  <img
    :src="loadingImg"
    class="mk-icon-loading"
  >
</template>

<script>
import loadingImg from '@assets/images/loading.gif'

export default {
  name: 'MkIconLoading',

  data () {
    return {
      loadingImg
    }
  }
}
</script>

<style lang="scss" scoped>
.mk-icon-loading {
  width: 16px;
  height: 16px;
}
</style>
