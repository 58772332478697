import Emoji from '@components/mk-im/emoji/emoji'
import { getType } from '@/utils/common.js'

export const MSESSAGE_TYPE = {
  text: 'text',
  image: 'image',
  audio: 'audio',
  video: 'video',
  news: 'news'
}

class Message {
  constructor (type, value) {
    this.msgtype = type
    this[type] = value
  }

  getContent () {
    return this[this.msgtype]
  }
}

export class TextMessage extends Message {
  constructor (val) {
    super(MSESSAGE_TYPE.text, val)
  }

  getMsgContent () {
    return this[MSESSAGE_TYPE.text].content
  }

  // 包含的表情 emoji 图片
  constructorForInput () {
    const value = this.getMsgContent()
    if (!value) {
      return
    }
    return value.replace(/\[.+?\]/g, function (v) {
      return Emoji.getImgByName(v)
    })
  }

  // 解析收到的消息[]/{}
  parseReceiverMessage () {
    return this.getMsgContent()
  }
}
export class ImageMessage extends Message {
  constructor (val) {
    super(MSESSAGE_TYPE.image, val)
  }

  getMsgImageUrl () {
    return this[MSESSAGE_TYPE.image].url
  }

  constructorForInput (width = '100px') {
    return `<img name="[图片]" src="${this.getMsgImageUrl()}" width="${width}" />`
  }

  parseReceiverMessage () {
    return '[图片]'
  }
}
export class AudioMessage extends Message {
  constructor (val) {
    super(MSESSAGE_TYPE.audio, val)
  }

  getAudioUrl () {
    return this[MSESSAGE_TYPE.audio].url
  }

  parseReceiverMessage () {
    return '[音频]'
  }
}
export class videoMessage extends Message {
  constructor (val) {
    super(MSESSAGE_TYPE.video, val)
  }

  getVideoUrl () {
    return this[MSESSAGE_TYPE.video].url
  }

  parseReceiverMessage () {
    return '[视频]'
  }
}
export class NewsMessage extends Message {
  constructor (val) {
    super(MSESSAGE_TYPE.news, val)
  }

  getMsgImageUrl () {
    return this[MSESSAGE_TYPE.news].url
  }

  parseReceiverMessage () {
    return '[消息]'
  }
}

// 消息体枚举
export const MSG_ENUM = {
  text: TextMessage,
  image: ImageMessage,
  audio: AudioMessage,
  video: videoMessage,
  news: NewsMessage
}

/**
 * 处理接收到的消息成 Message
 * @param {Object|Array} c
 * @return {Object|Array} 消息体[]/{}
*/
export function constructAllMessage (c) {
  if (Array.isArray(c)) {
    return c.map(item => constructMessage(item))
  } else {
    return constructMessage(c)
  }
}

/**
 * 构造对象数据成Message消息体
 * @param {Object} content
 * @return {Object} message
 */
const constructMessage = content => {
  if (content instanceof Message) {
    return content
  }
  let { msgtype } = content
  const _constructor = MSG_ENUM[msgtype]
  return new _constructor(content[msgtype])
}

/**
 * 处理接收到的消息，做成字符串, 草稿
 * @param {Object|Array} c
 * @return {String}
*/
export function parseReceiverMsg (c) {
  if (!c) { return }
  if (Array.isArray(c)) {
    let res = ''
    c.forEach(item => {
      const message = constructMessage(item)
      res += message.parseReceiverMessage()
    })
    return res
  } else {
    const message = constructMessage(c)
    return message.parseReceiverMessage()
  }
}

/**
 * 处理接收到的消息，做成输入框展示Dom
 * @param {Object|Array} c
 * @return {String}
*/
export function constructorInputShow (c) {
  if (getType(c) === 'array') {
    let res = ''
    c.forEach(item => {
      const message = constructMessage(item)
      if (message && message.constructorForInput) {
        res += message.constructorForInput()
      }
    })
    return res
  }
  if (getType(c) === 'object') {
    const message = constructMessage(c)
    if (message && message.constructorForInput) {
      return message.constructorForInput()
    } else {
      return ''
    }
  }
  if (getType(c) === 'string') {
    return c
  }
}
