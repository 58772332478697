<template>
  <div class="chat-window">
    <chat-window-top :platform="platform" />
    <div class="chat-window-main">
      <div class="chat-window-main-left">
        <chat-window-content
          style="flex:1;background-color:#F6F8FA;"
        ></chat-window-content>
        <chat-window-bottom :platform="platform" />
      </div>
      <div
        v-if="showChatWindowSide"
        class="chat-window-main-right"
      >
        <chat-window-side />
      </div>
    </div>
  </div>
</template>

<script>
import ChatWindowTop from './chat-window-top'
import ChatWindowContent from './chat-window-content'
import ChatWindowBottom from './chat-window-bottom'
import ChatWindowSide from './chat-window-side'
export default {
  name: 'ChatWindow',
  components: {
    ChatWindowTop,
    ChatWindowContent,
    ChatWindowBottom,
    ChatWindowSide
  },
  props: {
    platform: {
      type: String,
      default: ''
    }
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    showChatWindowSide () {
      return this.platform === 'web-fullscreen' && this.chatConfig.webDetailInfoVO?.sidebarSetting?.sidebar === 1
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-window{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .chat-window-main{
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .chat-window-main-left{
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .chat-window-main-right{
      border-left: 1px solid #F5F5F5;
    }
  }
}
</style>
