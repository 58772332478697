/* eslint-disable standard/no-callback-literal */
/* eslint-disable no-eval */
const hasOwnProperty = Object.prototype.hasOwnProperty
const toString = Object.prototype.toString

function getType (val, type) {
  return toString.call(val) === `[object ${type}]`
}

function isString (val) {
  return getType(val, 'String')
}

function isArray (val) {
  return val && Array.isArray(val)
}

function forEach (obj, iterator, context) {
  if (!obj) return
  if (obj.length && obj.length === +obj.length) {
    for (let i = 0; i < obj.length; i++) {
      if (iterator.call(context, obj[i], i, obj) === true) return
    }
  } else {
    for (const k in obj) {
      if (hasOwnProperty.call(obj, k)) {
        if (iterator.call(context, obj[k], k, obj) === true) return
      }
    }
  }
}

// Object to queryString
function serialize (obj) {
  const q = []
  forEach(obj, (val, key) => {
    if (isArray(val)) {
      forEach(val, (v) => {
        q.push(`${key}=${encodeURIComponent(v)}`)
      })
    } else {
      q.push(`${key}=${encodeURIComponent(val)}`)
    }
  })
  return q.join('&')
}

const ie678 = eval('!-[1,]')
const head = document.head || document.getElementsByTagName('head')[0]
function jsonpCore (options) {
  return new Promise((resolve, reject) => {
    let { url } = options
    const {
      data = {},
      timestamp = false,
      timeout = 1000 * 30,
      jsonName = 'callback',
      jsonpCallback = 'jsonp_' + buildUUID(),
      charset
    } = options
    let script = document.createElement('script')
    let done = false
    function callback (isSuc = false) {
      if (isSuc) done = true
      else reject(new Error('network error.'))
      script.onload = script.onerror = script.onreadystatechange = null
      if (head && script.parentNode) {
        head.removeChild(script)
        script = null
        window[jsonpCallback] = undefined
      }
    }
    function fixOnerror () {
      setTimeout(() => {
        if (!done) callback()
      }, timeout)
    }
    if (ie678) {
      script.onreadystatechange = function () {
        const readyState = this.readyState
        if (!done && (readyState === 'loaded' || readyState === 'complete')) callback(true)
      }
    } else {
      script.onload = function () {
        callback(true)
      }
      script.onerror = function () {
        callback()
      }
      if (window.opera) {
        fixOnerror()
      }
    }
    if (charset) script.charset = charset
    const search = serialize({
      ...data,
      [jsonName]: jsonpCallback
    })
    url += (url.indexOf('?') === -1 ? '?' : '&') + search
    if (timestamp) url += `&ts=${new Date().getTime()}`
    window[jsonpCallback] = function (json) {
      resolve(json)
    }
    script.src = url
    head.insertBefore(script, head.firstChild)
  })
}

function jsonp (opt) {
  if (isString(opt)) opt = { url: opt }
  return jsonpCore(opt)
}

const hexList = []
for (let i = 0; i <= 15; i++) {
  hexList[i] = i.toString(16)
}

function buildUUID () {
  let uuid = ''
  for (let i = 1; i <= 36; i++) {
    if (i === 9 || i === 14 || i === 19 || i === 24) {
      uuid += '-'
    } else if (i === 15) {
      uuid += 4
    } else if (i === 20) {
      uuid += hexList[(Math.random() * 4) | 8]
    } else {
      uuid += hexList[(Math.random() * 16) | 0]
    }
  }
  return uuid.replace(/-/g, '')
}

let unique = 0
export function buildShortUUID (prefix = '') {
  const time = Date.now()
  const random = Math.floor(Math.random() * 1000000000)
  unique++
  return prefix + '_' + random + unique + String(time)
}

export { jsonp }
