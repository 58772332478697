<template>
  <div
    class="chat-quote"
    @click="jumptoMessage"
  >
    <div class="quote-line"></div>
    <div class="quote-info">
      <div class="quote-info-name">
        {{ msgData.replyName }}
      </div>
      <div class="quote-info-article">
        <div
          v-if="msgData.replyContent.msgType === 'text'"
          class="text"
          v-html="getTextHtml(msgData.replyContent.data)"
        ></div>
        <img
          v-else-if="msgData.replyContent.msgType === 'image'"
          :src="msgData.replyContent.data"
          class="message-img"
        />

        <!-- 文档类型 -->
        <!-- word,excel,ppt,pdf -->
        <template v-else-if="docTypes.includes(msgData.replyContent.msgType)">
          <div class="doc-region">
            <template v-if="msgData.msgType!='video'">
              <img
                :src="DOC_ICONS[msgData.replyContent.msgType]"
                class="doc-img"
                :alt="msgData.replyContent.data.title"
              >
              <div class="doc-name">
                {{ msgData.replyContent.data.title }}
              </div>
            </template>
            <template v-else>
              [视频]
            </template>
          </div>
        </template>

        <div v-else>
          暂不支持类型消息
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Emoji from '@components/mk-im/emoji/emoji'
import {
  DOC_ICONS
} from '@/utils/enums/im'
export default {
  name: 'ChatQuote',
  props: {
    msgData: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      docTypes: Object.freeze(['doc', 'pdf', 'ppt', 'excel', 'txt', 'audio', 'video', 'link']), // 文档类型
      DOC_ICONS
    }
  },
  computed: {
    getTextHtml () {
      return val => {
        if (val) {
          return val.replace(/\[.+?\]/g, function (v) {
            return Emoji.getImgByName(v)
          })
        }

        return ''
      }
    }
  },
  methods: {
    jumptoMessage () {
      this.$emit('on-jump-message', this.msgData)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-quote {
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #cae1f5;

  .quote-line {
    width: 2px;
    background: #cee0f0;
    margin-right: 10px;
  }

  .quote-info {
    &-name {
      color: #8999ae;
      font-size: 12px;
      line-height: 18px;
    }

    &-article {
      .text {
        text-align: justify;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .message-img {
        width: 50px;
      }

      .doc-region {
        display: flex;
        flex-direction: row;

        .doc-img {
          width: 16px;
          height: 16px;
          border-radius: 2px;
          margin-right: 5px;
        }

        .doc-name {
          color: #888;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
