/*
 * @Author: xiaokun
 * @Date: 2020-11-13 15:26:25
 * @LastEditors: alwio
 * @LastEditTime: 2021-10-25 17:35:10
 * @Description:
 */

import $env from '@/plugins/env'
import BIZAPIS from './bizApi'
const { VUE_APP_DUSTESS_CLOUD_TYPE } = $env

export function getTokenApi () {
  return {
    api: BIZAPIS.endpointPutFilePost,
    params: {}
  }
}

/**
 * 获取默认占位图
 * 全平台项目统一使用此占位图
 */
export function getDefaultPlaceholderImage () {
  let map = {
    oss: 'https://cf-markting.oss-cn-hangzhou.aliyuncs.com/system/default/placeholder.png',
    cos: 'https://cf-markting-1256732272.cos.ap-shanghai.myqcloud.com/system/default/placeholder.png'
  }
  return map[VUE_APP_DUSTESS_CLOUD_TYPE]
}
