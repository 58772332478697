import TestPage from './index.vue'
import TestPageBuild from './build.vue'
export default [
  {
    path: '/test-wc',
    component: TestPage
  },
  {
    path: '/test-wc-build',
    component: TestPageBuild
  }
]
