<template>
  <div
    class="message-window-content"
  >
    <div class="message-window-content-tip">
      您好，现在是非工作时间，目前没有人工客服，您可以留下您的联系方式和公司名称。
    </div>
    <el-form
      ref="form"
      class="message-window-content-form"
      label-position="top"
      size="small"
      :model="messageForm"
      :rules="rules"
    >
      <el-form-item
        label="问题类型"
        prop="leaveMessageType"
      >
        <el-select
          v-model="messageForm.leaveMessageType"
          placeholder="请选择"
        >
          <el-option
            label="售前咨询"
            value="PRE_SALES"
          ></el-option>
          <el-option
            label="售后服务"
            value="AFTER_SALES"
          ></el-option>
          <el-option
            label="投诉建议"
            value="COMPLAINT_ADVICE"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="公司名称"
        prop="messageAccountName"
      >
        <el-input
          v-model="messageForm.messageAccountName"
          placeholder="请输入"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="问题描述"
        prop="questionDesc"
      >
        <el-input
          v-model="messageForm.questionDesc"
          type="textarea"
          maxlength="150"
          rows="4"
          resize="none"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        prop="phone"
      >
        <el-input
          v-model="messageForm.phone"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const validatePhone = (rule, value, callback) => {
  const regExp = /^1[3-9]\d{9}$/
  if (regExp.test(value)) {
    callback()
  } else {
    callback(new Error('请输入正确的手机号'))
  }
}
export default {
  name: 'MessageWindowContent',
  data () {
    return {
      messageForm: {
        leaveMessageType: '',
        messageAccountName: '',
        questionDesc: '',
        phone: ''
      },
      rules: {
        leaveMessageType: [
          { required: true, message: '请选择问题类型', trigger: 'change' }
        ],
        messageAccountName: [
          { required: true, message: '请输入公司名称', trigger: 'change' }
        ],
        questionDesc: [
          { required: true, message: '请输入问题描述', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: validatePhone, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    chatInfo () {
      return this.$store.state.chatInfo
    },
    chatConfig () {
      return this.$store.state.chatConfig
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = {
            conversationId: this.chatInfo.conversationId || '',
            address: this.chatInfo.address,
            channel: 'WEB',
            account: this.chatConfig.account,
            ipAddress: this.chatInfo.visitorId,
            ...this.messageForm
          }
          const data = this.$fetchHelper(JAVACHATSERVICE.LeaveMessageAddLeaveMessageApi.apiLeaveMessageAddLeaveMessagePost, params)
          data.then(() => {
            this.$notify({ type: 'success', message: '留言成功' })
            this.$refs.form.resetFields()
            this.$emit('close')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.message-window-content{
  flex: 1;
  padding: 14px;
  overflow: auto;
  background-color: #f6f8fa;

  &-tip{
    background-color: #dde6f4;
    padding: 10px;
    border-radius: 4px;
    color: #4d78bd;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  &-form{
    ::v-deep .el-form-item__label{
      line-height: 20px;
      color: #262626;
      padding: 2px 0 8px;
    }

    .el-select{
      width: 100%;
    }
  }
}
</style>
