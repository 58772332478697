<template>
  <i
    :class="iconName"
    v-on="$listeners"
  ></i>
</template>

<script>
export default {
  name: 'MkIconfont',
  props: {
    iconClass: {
      type: String,
      required: true
    }
  },
  computed: {
    iconName () {
      return `kf-icon ${this.iconClass}`
    }
  }
}
</script>

<style lang="scss" scoped>
.kf-icon {
  font-size: 16px;
}
</style>
