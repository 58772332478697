import LinkFailure from './index.vue'
export default [
  {
    name: '404',
    meta: { spm: 'b0403' },

    path: '/link-failure',
    component: LinkFailure
  }
]
