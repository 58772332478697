import { TextMessage, ImageMessage } from '@/utils/models/message.js'

export const parseInnerHtml = str => {
  let res = []
  let lastIdx = 0
  // 提取emoji
  // eslint-disable-next-line
  str = str.replace(/<img name=\"emoji\".+?title=\"(\[.+?\])\"[^>]*>/gi, "$1");
  // 提取图片
  // eslint-disable-next-line
  str.replace(/<img name=\"\[图片\]\".+?src=\"(.+?)\".+?[^>]*>/gi, function(
    match,
    group,
    index,
    str
  ) {
    let ahead = str.substring(lastIdx, index)
    if (ahead && !isEmpty(ahead)) {
      res.push(new TextMessage({ content: ahead }))
    }
    res.push(new ImageMessage({ url: group }))
    lastIdx = index + match.length
  })
  const lastText = str.substring(lastIdx)
  if (lastText && !isEmpty(lastText)) {
    res.push(new TextMessage({ content: lastText }))
  }
  return res
}

function isEmpty (str) {
  const result = str.replace(/&nbsp;/ig, ' ').trim()
  return !result.length
}
