<template>
  <div class="kf-message">
    <mk-iconfont
      icon-class="iconfont-2492785 duero-icon-2492785-liuyan02 icon-normal"
      :class="disabled?'not-allowed':'cursor'"
      @click.native="handleClick"
    />
  </div>
</template>

<script>

export default {
  name: 'Message',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick () {
      this.$emit('call-click-message')
    }
  }
}
</script>
