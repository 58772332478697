<template>
  <div class="chat-window-h5">
    <chat-window-wap />
  </div>
</template>

<script>
import ChatWindowWap from '@components/chat-window-wap'

export default {
  name: 'ChatH5',
  components: {
    ChatWindowWap
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    onlyMessage () {
      return this.$store.state.openSocket === false && this.chatConfig.leaveMessage?.isEnable
    }
  },
  created () {
    const queryString = window.location.href.split('?')[1]
    this.onlyMessage && this.$router.replace({
      path: '/chat-h5/message' + (queryString ? '?' + queryString : '')
    })
  }
  // watch: {
  //   onlyMessage (val) {
  //     val && this.$router.replace({
  //       path: '/chat-h5/message'
  //     })
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.chat-window-h5{
  width: 100%;
  height: 100%;
}
</style>
