<template>
  <div>
    该链接无效！
  </div>
</template>

<script>
export default {
  name: 'LinkFailure'
}
</script>
