<template>
  <div
    class="chat-card"
    @click.stop="openLink"
  >
    <main>
      <div class="title doubleOverflow">
        {{ cardInfo.news.title }}
      </div>
      <div class="card-content">
        <div class="card-content-desc doubleOverflow">
          {{ cardInfo.news.desc }}
        </div>
        <img
          :src="cardInfo.news.imgUrl"
          class="card-content-cover"
        >
      </div>
    </main>
  </div>
</template>
<script>
export default {
  props: {
    cardInfo: {
      type: Object,
      default: {
        msgtype: '',
        news: {
          link: '', // H5消息页面url 必填
          title: '', // H5消息标题
          desc: '', // H5消息摘要
          imgUrl: '', // H5消息封面图片URL
          materialType: '' // 营销素材类型
        }
      }
    }
  },
  methods: {
    openLink () {
      window.open(this.cardInfo.news?.link)
    }
  }
}
</script>
<style lang="scss" scoped>
.chat-card {
  .title {
    font-size: 14px;
    color: #262626;
    margin-bottom: 4px;
    line-height: 20px;
    text-align: justify;
  }

  .card-content {
    display: flex;
    flex-direction: row;

    &-desc {
      color: #A1A1A1;
      font-size: 12px;
      line-height: 18px;
      margin-right: 12px;
      text-align: justify;
      width: calc(100% - 55px);
    }

    &-cover {
      width: 43px;
      height: 43px;
      border-radius: 2px;
    }
  }

  .doubleOverflow {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
</style>
