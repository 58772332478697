<template>
  <div class="message-window">
    <message-window-top
      :theme="theme"
      :show-close="showClose"
      @close="close"
    />
    <message-window-content
      ref="MessageWindowContent"
      @close="close"
    />
    <message-window-bottom
      :theme="theme"
      @handleSubmit="handleSubmit"
    />
  </div>
</template>

<script>
import MessageWindowTop from './message-window-top'
import MessageWindowContent from './message-window-content'
import MessageWindowBottom from './message-window-bottom'
export default {
  name: 'MessageWindow',
  components: {
    MessageWindowTop,
    MessageWindowContent,
    MessageWindowBottom
  },
  props: {
    theme: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.MessageWindowContent.handleSubmit()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.message-window{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
