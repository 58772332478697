<template>
  <div
    class="message-window-top"
    :style="style"
  >
    <span class="message-window-top-title">
      请您留言
    </span>
    <div class="message-window-top-operate">
      <mk-iconfont
        v-show="showClose"
        icon-class="iconfont-2492785 duero-icon-2492785-kf_cuowu"
        style="opacity:0.6;"
        @click="$emit('close')"
      ></mk-iconfont>
    </div>
  </div>
</template>

<script>
import ChatTopBg from '@/assets/images/chat-top-bg.png'
export default {
  name: 'MessageWindowTop',
  props: {
    theme: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    style () {
      return {
        '--bg-color': this.theme,
        '--bg-image': `url(${ChatTopBg})`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-window-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 14px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  background-color: var(--bg-color);
  background-image: var(--bg-image);
  background-size: auto 100%;
  background-position: top right;
  background-repeat: no-repeat;

  &-title{
    font-size: 16px;
    line-height: 22px;
  }

  &-operate{
    line-height: 1;

    .kf-icon {
      font-size: 20px;
      cursor: pointer;
    }
  }
}
</style>
