import ChatWeb from './index.vue'

export default [
  {
    name: '聊天窗口PC',
    meta: { spm: 'b0406' },

    path: '/chat',
    component: ChatWeb
  }
]
