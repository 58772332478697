<template>
  <div>
    <div
      class="chat-window-bottom"
      :class="csIsOffline ? 'disable' : ''"
    >
      <div v-if="chatAction !== '999'">
        <chat-operate
          :toolbar="toolbar"
          @call-select-emoji="handleSelectEmoji"
          @call-click-message="handleClickMessage"
          @call-upload-success="handleUploadSuccess"
          @show-uploadloading="showUploadloading"
        />
        <input-div
          ref="sendMsgDiv"
          :max-height="inputMaxHeight"
          :min-height="inputMinHeight"
          :theme="theme"
          :disabled="csIsOffline"
          :message.sync="msgContent"
          :custom-placeholder="placeholer"
          @send-message="handleSendMsg"
        />
      </div>
      <close-operate
        v-else
        :platform="platform"
        @call-click-message="handleClickMessage"
      />
      <div
        v-show="showFlieLoading"
        class="file-loading"
      >
        <mk-icon-loading
          class="msg-loading"
        /> &nbsp;文件发送中
      </div>
    </div>
    <DrawerMessage
      ref="DrawerMessage"
      :theme="theme"
    />
    <DialogMessage
      ref="DialogMessage"
      :theme="theme"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChatOperate from '@components/mk-im/chat-operate'
import InputDiv from '@components/mk-im/input-div'
import CloseOperate from './close-operate'
import DrawerMessage from '@components/drawer-message'
import MkIconLoading from '@components/mk-icon-loading'
import DialogMessage from '@components/dialog-message'
import { TextMessage } from '@/utils/models/message'
import { FILE_SUFFIX_TYPE } from '@utils/enums/im.js'
import { EventBus } from '@utils/common.js'
import _cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'ChatWindowBottom',
  components: {
    ChatOperate,
    MkIconLoading,
    InputDiv,
    CloseOperate,
    DrawerMessage,
    DialogMessage
  },
  props: {
    platform: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      msgContent: [],
      msgSendQueque: [],
      showFlieLoading: false
    }
  },
  computed: {
    ...mapGetters({
      csIsOffline: 'csIsOffline'
    }),
    chatConfig () {
      return this.$store.state.chatConfig
    },
    chatInfo () {
      return this.$store.state.chatInfo
    },
    chatAction () {
      return this.$store.state.chatAction
    },
    placeholer () {
      const placeholder = this.chatInfo.status === 'SUCCESSFUL' ? this.chatConfig.webDetailInfoVO?.inputBoxAreaSetting?.serverIntroMsg : this.chatInfo.status === 'QUEUED' ? this.chatConfig.webDetailInfoVO?.inputBoxAreaSetting?.waitIntroMsg : ''
      return placeholder || ''
    },
    inputMaxHeight () {
      return this.platform === 'web-window' ? 40 : 60
    },
    inputMinHeight () {
      return this.platform === 'web-window' ? 40 : 60
    },
    theme () {
      return this.chatConfig.webDetailInfoVO?.baseSetting?.themeColor || undefined
    },
    toolbar () {
      let toolbar = ['emoji', 'uploadImg']
      const menubar = this.chatConfig.webDetailInfoVO?.inputBoxAreaSetting?.menuBar || []
      menubar.includes(1) && toolbar.push('uploadFile')
      menubar.includes(0) && this.chatConfig.leaveMessage?.isEnable && toolbar.push('message')
      return toolbar
    }
  },
  watch: {
    'msgSendQueque.length' () {
      this.handleSendQuequeMessage()
    },
    chatAction (val) {
      if (val === '999') {
        this.$refs.sendMsgDiv.inputBlur()
      }
    }
  },
  created () {
    EventBus.$on('sendQuequeMsg', this.handleSendQuequeMessage)
  },
  beforeDestroy () {
    EventBus.$off('sendQuequeMsg', this.handleSendQuequeMessage)
  },
  methods: {
    handleSendQuequeMessage () {
      if (this.msgSendQueque.length && this.checkCanChat()) {
        let msgSendQueque = _cloneDeep(this.msgSendQueque)
        const sendMsgList = msgSendQueque.map(item => {
          return {
            conversationId: this.chatInfo.conversationId,
            account: this.chatConfig.account,
            action: 'msg', // 消息类型
            content: item.content,
            format: 'json', // 消息格式
            receiver: this.chatInfo.kfId,
            receiverRole: 'CUSTOMER_SERVICE',
            sender: this.chatInfo.visitorId,
            senderRole: 'USER'
          }
        })
        this.handleSubmitMsg(sendMsgList, msgSendQueque)
        this.msgSendQueque = []
      }
    },
    handleSelectEmoji (val) {
      const textMsg = new TextMessage({ content: val })
      this.$refs['sendMsgDiv'].setInnerHtml(textMsg)
    },
    handleClickMessage () {
      this.platform === 'web-window' ? (this.$refs.DialogMessage.visible = true) : (this.$refs.DrawerMessage.visible = true)
      const spmClickMessage = 'c0402.d0415'/** data-spm-desc: 访客聊天框-进行留言 **/
      window.Monitor?.sendSPM(spmClickMessage)
    },
    showUploadloading (val = true) {
      this.showFlieLoading = val
    },
    handleUploadSuccess (fileInfo) {
      const file = fileInfo.file
      const index = file.name.lastIndexOf('.')
      const suffix = file.name.slice(index).toLocaleLowerCase()
      const msgType = FILE_SUFFIX_TYPE[suffix]
      let msg = { msgType }
      if (msgType === 'image') {
        msg.data = fileInfo.linkUrl
      } else {
        msg.data = {
          title: file.name,
          size: file.size,
          url: fileInfo.linkUrl
        }
      }
      this.addMsgQueque(JSON.stringify(msg))
    },
    /**
     * 处理链接
     */
    toLink (txtContent) {
      let checkWww = 'w{3}' + '[^\\s]*'
      let checkHttp = '(https|http|ftp|rtsp|mms)://' + '[^\\s]*'
      let strRegex = checkWww + '|' + checkHttp
      let httpReg = new RegExp(strRegex, 'gi')
      let formatTxtContent = txtContent.replace(httpReg, function (httpText) {
        if (httpText.search('http') < 0 && httpText.search('HTTP') < 0) {
          return `<a style='color:#016EEF' href="http://${httpText}" target="_blank">${httpText}</a>`
        } else {
          return `<a style='color:#016EEF' href="${httpText}" target="_blank">${httpText}</a>`
        }
      })
      return formatTxtContent
    },
    handleSendMsg (val) {
      val.forEach(item => this.sendMsg(item))
      this.clearContent()
      const spmSendMsg = 'c0402.d0402'/** data-spm-desc: 访客聊天框-发送消息 **/
      window.Monitor?.sendSPM(spmSendMsg)
    },
    async sendMsg (content) {
      if (content.text) {
        content.text.content = this.toLink(content.text.content)
      }
      const msg = {
        msgType: content.msgtype,
        data: content.text ? content.text.content : content.image ? content.image.url : ''
      }
      this.addMsgQueque(JSON.stringify(msg))
    },
    addMsgQueque (content) {
      const chatRecordList = [...this.$store.state.chatRecordList]
      const canChat = this.checkCanChat()
      let chatRecord = {
        conversationId: this.chatInfo.conversationId || '',
        fromUserId: this.chatInfo.visitorId,
        fromUserName: '我',
        fromUserType: 2,
        uniqueMsgId: '',
        toUserId: '',
        sendTime: Date.now(),
        success: !canChat,
        loading: canChat,
        isRead: false,
        withdraw: false,
        content
      }
      chatRecordList.push(chatRecord)
      this.$store.dispatch('setChatRecordList', chatRecordList)
      this.msgSendQueque.push(chatRecord)
    },
    async handleSubmitMsg (sendMsgList, msgSendQueque) {
      let success = false
      try {
        const { data, code } = await JAVAIMSERVICE.MessageControllerApi.apiMessageSendsPost(sendMsgList)
        if (code === 500200) {
          success = true
        }
      } catch (error) {
      }
      this.updateChatRecordList([...msgSendQueque], success)
    },
    updateChatRecordList (recordList, success = true) {
      let recordMap = new Map()
      recordList.forEach(item => {
        recordMap.set(item.sendTime, true)
      })
      const chatRecordList = JSON.parse(JSON.stringify(this.$store.state.chatRecordList))
      for (let record of chatRecordList) {
        if (recordMap.has(record.sendTime) && record.loading) {
          record.success = success
          record.loading = false
        }
      }
      this.$store.dispatch('setChatRecordList', chatRecordList)
    },
    clearContent () {
      this.$refs['sendMsgDiv'].clearInputContent()
    },
    checkCanChat () {
      return Boolean(this.chatInfo.kfId && this.chatInfo.conversationId)
    }
  }
}
</script>

<style lang='scss' scoped>
.chat-window-bottom {
  position: relative;
  background: #fff;
  border-top: 1px solid #f5f5f5;
  &.disable {
    background: #f7f7f7;
  }
  .file-loading{
    position: absolute;
    left: 50%;
     top: -56px;
    width: 115px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    background: #494949;
    border-radius: 4px;
    font-size: 14px;
    transform: translateX(-54px);
    .msg-loading{
        vertical-align: middle;
    }
  }
}
</style>
