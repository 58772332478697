/*
 * @Author: huangzq
 * @Date: 2019-10-20 23:01:53
 * @Last Modified by: huangzq
 * @Last Modified time: 2020-08-14 11:20:38
 * @Desc: Storage的相关操作
 */

// storage.setItem
const setItem = (key, value, storage = window.sessionStorage) => {
  storage.setItem(key, JSON.stringify(value))
}

// storage.getItem
const getItem = (key, storage = window.sessionStorage) => {
  const value = storage.getItem(key)
  return value ? JSON.parse(value) : null
}

// storage.removeItem
const removeItem = (key, storage = window.sessionStorage) => {
  storage.removeItem(key)
}

export default { getItem, setItem, removeItem }
