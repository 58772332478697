/*
 * @Descripttion:
 * @version:
 * @Author: yangym
 * @Date: 2020-03-16 11:05:26
 * @LastEditors: huangzq
 * @LastEditTime: 2020-11-13 10:30:59
 */
const errorCode: any = {
  // 后端出错code
  500500: '您的当前访问出现问题，请联系管理员'
}

export default errorCode
