let needLoadingQueue = [] // 需要loading的队列
const REPEAT_REQUEST = 'REPEAT_REQUEST'

export default (Vue) => {
  /**
   * @param fn api名称
   * @param needLoading 是否需要等待上一个相同请求完成后才能继续。默认为true，即上一个没执行完会返回一个重复请求的reject对象
   * @param params 参数
   * @param opt axios配置，比如timeout、content-type啥的
   * @returns {Promise<*>}
   */
  Vue.prototype.$fetchHelper = async function (fn, { needLoading = true, ...params } = {}, opt) {
    const fnStr = fn.toString()

    try {
      if (needLoading) {
        if (needLoadingQueue.includes(fnStr)) {
          // eslint-disable-next-line
          return Promise.reject({
            type: REPEAT_REQUEST,
            msg: '重复请求'
          })
        }

        needLoadingQueue.push(fnStr)
      }

      const { success, code, data, msg } = await fn(params, opt)

      if (success && code === 500200) {
        return data
      }

      // eslint-disable-next-line
      return Promise.reject({
        code,
        msg
      })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      if (needLoading) {
        const index = needLoadingQueue.indexOf(fnStr)

        index > -1 && needLoadingQueue.splice(index, 1)
      }
    }
  }
}
