import ChatWebFullScreen from './index.vue'

export default [
  {
    name: '聊天窗口PC全屏',
    meta: { spm: 'b0405' },

    path: '/chat-fullscreen',
    component: ChatWebFullScreen
  }
]
