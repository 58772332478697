<template>
  <div
    ref="refKfEmoji"
    class="kf-emoji"
  >
    <div @click.stop="onShow">
      <slot>
        <div
          :class="disabled?'not-allowed':'cursor'"
        >
          <mk-iconfont
            icon-class="iconfont-2492785 duero-icon-2492785-biaoqing01 icon-normal"
            :class="show ? 'icon-active' : ''"
          />
        </div>
      </slot>
    </div>

    <div
      v-if="show"
      class="emoji-list-wrapper"
      :style="{ top, left, width, height }"
    >
      <emoji-List
        :size="size"
        :row-num="rowNum"
        @call-select-emoji="selectEmoji"
      />
    </div>
  </div>
</template>

<script>
import EmojiList from './emoji-list.vue'

export default {
  name: 'Emoji',
  components: {
    EmojiList
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    top: {
      type: String,
      default: '-188px'
    },
    left: {
      type: String,
      default: '-10px'
    },
    width: {
      type: String,
      default: '360px'
    },
    height: {
      type: String,
      default: '174px'
    },
    size: {
      type: Number,
      default: 24
    },
    rowNum: {
      type: Number,
      default: 12
    }
  },
  data () {
    return {
      show: false
      // '调皮', '呲牙', '惊讶', '流汗', '憨笑', '大兵', '鼓掌', '糗大了', '坏笑', '啤酒', '篮球', '乒乓','足球', '瓢虫', '便便','爱你', 'NO', 'OK','磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极'
    }
  },
  watch: {
    disabled: {
      handler: function (val) {
        if (val) {
          this.show = false
        }
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleEmojiPanel, false)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleEmojiPanel, false)
  },
  methods: {
    onShow () {
      if (this.disabled) { return }
      this.toggleShow()
    },
    toggleShow (val) {
      this.show = val || !this.show
    },
    selectEmoji (emojiName) {
      this.toggleShow(false)
      this.$emit('call-select-emoji', emojiName)
    },
    handleEmojiPanel (e) {
      const ref = this.$refs.refKfEmoji
      if (!(ref && ref.contains(e.target))) {
        this.show = false
      }
    }
  }

}
</script>

<style lang='scss' scoped>
.kf-emoji {
  position: relative;

  .icon-active {
    color: #009cff;
  }

  .emoji-list-wrapper{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    overflow: hidden;
    position: absolute;
  }
}
</style>
