var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-msg-input kf-emoji"},[_c('div',{staticClass:"input-content",style:({
      minHeight: _vm.minHeight+'px',
      maxHeight: _vm.maxHeight+'px'
    }),attrs:{"id":"input-content","inputmode":"search","placeholder":_vm.customPlaceholder,"maxlength":"500","contenteditable":!_vm.disabled,"value":_vm.content},on:{"focus":_vm.inputFocus,"blur":_vm.inputBlur,"paste":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handlePaste.apply(null, arguments)},"drop":_vm.handleDrop,"keydown":_vm.handleKeydown,"mouseup":_vm.handleMouseup,"mouseleave":_vm.handleMouseleave}}),_vm._t("default",function(){return [(_vm.isBtnSend)?_c('div',{staticClass:"clearfix operate-btns"},[_c('div',{class:[
          'btn',
          'btn-send-msg',
          _vm.disabled?'disable':''
        ],style:(_vm.sendBtnStyle),on:{"click":_vm.sendMessage}},[_vm._v(" 发送 ")])]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }