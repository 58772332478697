<template>
  <div
    class="chat-close-operate"
    :class="platform"
  >
    <div class="close-operate-btn btn-reconnect">
      <div
        @click="handleClickReconnect"
      >
        <mk-iconfont
          icon-class="iconfont-2492785 duero-icon-2492785-jianlihuihua icon-normal"
        />
      </div>
      <span>建立会话</span>
    </div>
    <div
      v-if="canMessage"
      class="split-line"
    ></div>
    <div
      v-if="canMessage"
      class="close-operate-btn btn-message"
    >
      <div
        @click="handleClickMessage"
      >
        <mk-iconfont
          icon-class="iconfont-2492785 duero-icon-2492785-liuyan01 icon-normal"
        />
      </div>
      <span>进行留言</span>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@utils/common.js'
export default {
  name: 'CloseOparate',
  props: {
    platform: {
      type: String,
      default: ''
    }
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    menuBar () {
      return this.chatConfig.webDetailInfoVO?.inputBoxAreaSetting?.menuBar || []
    },
    canMessage () {
      return this.chatConfig.leaveMessage?.isEnable
    }
  },
  methods: {
    handleClickReconnect () {
      EventBus.$emit('connectChatWindow')
    },
    handleClickMessage () {
      this.$emit('call-click-message')
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-close-operate{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &.web-window{
    height: 100px;

    .split-line{
      margin: 0 50px;
    }
  }

  &.web-fullscreen{
    height: 136px;

    .split-line{
      margin: 0 75px;
    }
  }

  .close-operate-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    div{
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      margin-bottom: 6px;
      background-color: #f5f5f5;
      border-radius: 12px;

      i{
        font-size: 26px;
        color: #b5b5b5;
      }

      &:hover, &:active{
        background-color: #eaf4fe;

        i{
          color: #0582f1;
        }
      }
    }

    span{
      font-size: 13px;
      line-height: 18px;
      color: #888;
    }
  }

  .split-line{
    width: 1px;
    height: 50px;
    background-color: #f5f5f5;
  }
}
</style>
