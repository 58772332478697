<template>
  <div class="chat-window-bottom">
    <div v-if="chatAction !== '999'">
      <div class="chat-window-bottom-main">
        <div class="chat-window-bottom-main-input">
          <input-div
            ref="sendMsgDiv"
            :max-height="100"
            :min-height="20"
            :message.sync="messageContent"
            :custom-placeholder="placeholer"
            @send-message="handleSendMsg"
          >
            <div></div>
          </input-div>
        </div>
        <div class="chat-window-bottom-main-btns">
          <mk-iconfont
            style="font-size:26px;"
            icon-class="iconfont-2560775 duero-icon-2560775-gengduo1"
            @click.stop="showMoreBtns"
          ></mk-iconfont>
          <img
            class="btn-send"
            :src="send"
            alt=""
            @click="$refs.sendMsgDiv.sendMessage()"
          >
        </div>
      </div>
      <div
        v-show="showMore && !showEmojiList"
        ref="more"
        class="chat-window-bottom-more"
      >
        <div
          class="chat-window-bottom-more-btns"
        >
          <div
            class="chat-window-bottom-more-btn"
            @click="handleClickEmoji"
          >
            <div class="btn-icon">
              <mk-iconfont
                style="font-size:29px;"
                icon-class="iconfont-2560775 duero-icon-2560775-biaoqing"
              ></mk-iconfont>
            </div>
            <span class="btn-name">表情</span>
          </div>
          <upload-img
            @upload-success="uploadSuccess"
            @show-upload="showUploadloading"
          >
            <div class="chat-window-bottom-more-btn">
              <div class="btn-icon">
                <mk-iconfont
                  style="font-size:27px;"
                  icon-class="iconfont-2560775 duero-icon-2560775-tupian1"
                ></mk-iconfont>
              </div>
              <span class="btn-name">图片</span>
            </div>
          </upload-img>
          <upload-file
            v-if="menuBar.includes(1)"
            @upload-success="uploadSuccess"
            @show-upload="showUploadloading"
          >
            <div class="chat-window-bottom-more-btn">
              <div class="btn-icon">
                <mk-iconfont
                  style="font-size:29px;"
                  icon-class="iconfont-2560775 duero-icon-2560775-wenjian"
                ></mk-iconfont>
              </div>
              <span class="btn-name">文件</span>
            </div>
          </upload-file>

          <!-- <div
            v-if="canMessage"
            class="chat-window-bottom-more-btn"
            @click="goMessage"
          >
            <div class="btn-icon">
              <mk-iconfont
                style="font-size:30px;"
                icon-class="iconfont-2560775 duero-icon-2560775-liuyan"
              ></mk-iconfont>
            </div>
            <span class="btn-name">留言</span>
          </div> -->
        </div>
      </div>
      <div
        v-if="showEmojiList"
        class="emoji-list"
      >
        <emoji-List
          :size="32"
          :reduce-num="3"
          @call-select-emoji="selectEmoji"
        />
      </div>
    </div>
    <div v-else>
      <close-oparate
        platform="web-window"
        @call-click-message="goMessage"
      >
      </close-oparate>
    </div>
    <div
      v-show="showFlieLoading"
      class="file-loading"
    >
      <mk-icon-loading
        class="msg-loading"
      /> &nbsp;文件发送中
    </div>
  </div>
</template>

<script>
import Send from '@/assets/images/send.png'
import UploadImg from '@components/mk-im/upload-img'
import UploadFile from '@components/mk-im/upload-file'
import InputDiv from '@components/mk-im/input-div'
import CloseOparate from '@components/chat-window/chat-window-bottom/close-operate'
import EmojiList from '@components/mk-im/emoji/emoji-list.vue'
import { TextMessage } from '@/utils/models/message'
import { FILE_SUFFIX_TYPE } from '@utils/enums/im.js'
import { EventBus } from '@utils/common.js'
import _cloneDeep from 'lodash/cloneDeep'
import MkIconLoading from '@components/mk-icon-loading'
export default {
  name: 'ChatWindowBottom',
  components: {
    UploadImg,
    UploadFile,
    InputDiv,
    EmojiList,
    MkIconLoading,
    CloseOparate
  },
  data () {
    return {
      showMore: false,
      showEmojiList: false,
      showFlieLoading: false,
      send: Send,
      test: '',
      showMessage: false,
      messageContent: [],
      msgSendQueque: []
    }
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    chatInfo () {
      return this.$store.state.chatInfo
    },
    chatAction () {
      return this.$store.state.chatAction
    },
    placeholer () {
      const placeholder = this.chatInfo.status === 'SUCCESSFUL' ? this.chatConfig.webDetailInfoVO?.inputBoxAreaSetting?.serverIntroMsg : this.chatInfo.status === 'QUEUED' ? this.chatConfig.webDetailInfoVO?.inputBoxAreaSetting?.waitIntroMsg : ''
      return placeholder || ''
    },
    menuBar () {
      return this.chatConfig.webDetailInfoVO?.inputBoxAreaSetting?.menuBar || []
    },
    canMessage () {
      return this.menuBar.includes(0) && this.chatConfig.leaveMessage?.isEnable
    }
  },
  watch: {
    'msgSendQueque.length' () {
      this.handleSendQuequeMessage()
    },
    chatAction (val) {
      if (val === '999') {
        this.$refs.sendMsgDiv.inputBlur()
      }
    }
  },
  created () {
    document.body.addEventListener('click', this.hiddeMoreBtns)
    EventBus.$on('sendQuequeMsg', this.handleSendQuequeMessage)
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.hiddeMoreBtns)
    EventBus.$off('sendQuequeMsg', this.handleSendQuequeMessage)
  },
  methods: {
    handleSendQuequeMessage () {
      if (this.msgSendQueque.length && this.checkCanChat()) {
        let msgSendQueque = _cloneDeep(this.msgSendQueque)
        const sendMsgList = msgSendQueque.map(item => {
          return {
            conversationId: this.chatInfo.conversationId,
            account: this.chatConfig.account,
            action: 'msg', // 消息类型
            content: item.content,
            format: 'json', // 消息格式
            receiver: this.chatInfo.kfId,
            receiverRole: 'CUSTOMER_SERVICE',
            sender: this.chatInfo.visitorId,
            senderRole: 'USER'
          }
        })
        this.handleSubmitMsg(sendMsgList, msgSendQueque)
        this.msgSendQueque = []
      }
    },
    async showMoreBtns () {
      this.showMore = !this.showMore
      if (!this.showMore) {
        this.showEmojiList = false
      }
    },
    hiddeMoreBtns (e) {
      if (this.showMore && this.$refs.more && !this.$refs.more.contains(e.target)) {
        this.showMore = false
        this.showEmojiList = false
      }
    },
    handleClickEmoji () {
      this.showEmojiList = true
      this.$emit('contentScrollToBottom')
    },
    showUploadloading (val = true) {
      this.showFlieLoading = val
    },
    uploadSuccess (fileInfo) {
      const file = fileInfo.file
      const index = file.name.lastIndexOf('.')
      const suffix = file.name.slice(index).toLocaleLowerCase()
      const msgType = FILE_SUFFIX_TYPE[suffix]
      let msg = { msgType }
      if (msgType === 'image') {
        msg.data = fileInfo.linkUrl
      } else {
        msg.data = {
          title: file.name,
          size: file.size,
          url: fileInfo.linkUrl
        }
      }
      this.addMsgQueque(JSON.stringify(msg))
    },
    /**
     * 处理链接
     */
    toLink (txtContent) {
      let checkWww = 'w{3}' + '[^\\s]*'
      let checkHttp = '(https|http|ftp|rtsp|mms)://' + '[^\\s]*'
      let strRegex = checkWww + '|' + checkHttp
      let httpReg = new RegExp(strRegex, 'gi')
      let formatTxtContent = txtContent.replace(httpReg, function (httpText) {
        if (httpText.search('http') < 0 && httpText.search('HTTP') < 0) {
          return `<a style='color:#016EEF' href="http://${httpText}" target="_blank">${httpText}</a>`
        } else {
          return `<a style='color:#016EEF' href="${httpText}" target="_blank">${httpText}</a>`
        }
      })
      return formatTxtContent
    },
    handleSendMsg (val) {
      val.forEach(item => this.sendMsg(item))
      this.clearContent()
    },
    sendMsg (content) {
      if (content.text) {
        content.text.content = this.toLink(content.text.content)
      }
      const msg = {
        msgType: content.msgtype,
        data: content.text ? content.text.content : content.image ? content.image.url : ''
      }
      this.addMsgQueque(JSON.stringify(msg))
    },
    clearContent () {
      this.$refs['sendMsgDiv'].clearInputContent()
    },
    goMessage () {
      this.$router.push({
        path: '/chat-h5/message',
        query: {
          ...this.$route.query,
          fromChatWindow: true
        }
      })
    },
    selectEmoji (val) {
      const textMsg = new TextMessage({ content: val })
      this.$refs['sendMsgDiv'].setInnerHtml(textMsg)
      setTimeout(() => {
        document.querySelector('.chat-window-bottom').scrollIntoView()
      }, 210)
    },
    addMsgQueque (content) {
      const chatRecordList = [...this.$store.state.chatRecordList]
      const canChat = this.checkCanChat()
      let chatRecord = {
        conversationId: this.chatInfo.conversationId || '',
        fromUserId: this.chatInfo.visitorId,
        fromUserName: '我',
        fromUserType: 2,
        uniqueMsgId: '',
        toUserId: '',
        sendTime: Date.now(),
        success: !canChat,
        loading: canChat,
        isRead: false,
        withdraw: false,
        content
      }
      chatRecordList.push(chatRecord)
      this.$store.dispatch('setChatRecordList', chatRecordList)
      this.msgSendQueque.push(chatRecord)
    },
    async handleSubmitMsg (sendMsgList, msgSendQueque) {
      let success = false
      try {
        const { data, code } = await JAVAIMSERVICE.MessageControllerApi.apiMessageSendsPost(sendMsgList)
        if (code === 500200) {
          success = true
        }
      } catch (error) {
      }
      this.updateChatRecordList([...msgSendQueque], success)
    },
    updateChatRecordList (recordList, success = true) {
      let recordMap = new Map()
      recordList.forEach(item => {
        recordMap.set(item.sendTime, true)
      })
      const chatRecordList = JSON.parse(JSON.stringify(this.$store.state.chatRecordList))
      for (let record of chatRecordList) {
        if (recordMap.has(record.sendTime) && record.loading) {
          record.success = success
          record.loading = false
        }
      }
      this.$store.dispatch('setChatRecordList', chatRecordList)
    },
    checkCanChat () {
      return Boolean(this.chatInfo.kfId && this.chatInfo.conversationId)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-window-bottom {
  border-top: 1px solid #f5f5f5;
  background-color: #f5f6f6;
  position: relative;
  &-main {
    display: flex;
    align-items: flex-start;
    padding: 7.5px 10px;
    &-input {
      flex: 1;
      border-radius: 3px;
      padding: 7.5px 11.5px;
      background-color: #fff;
      word-break: break-all;
      overflow: hidden;

      ::v-deep .send-msg-input {
        .input-content {
          padding: 0;
        }
      }
    }
    &-btns {
      display: flex;
      align-items: center;
      margin-top: 4px;
      .btn-send {
        width: 27px;
        height: 27px;
      }
      i,
      img {
        margin-left: 10px;
      }
    }
  }
  &-more {
    height: 120px;
    outline: none;

    &-btns {
      height: 100%;
      padding: 12px 0 28px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    &-btn {
      display: flex;
      align-items: center;
      flex-direction: column;
      .btn-icon {
        line-height: 59px;
        width: 59px;
        height: 59px;
        margin-bottom: 3px;
        text-align: center;
        color: #888;
        border-radius: 6px;
        background-color: #fefffe;
      }
      .btn-name {
        font-size: 13px;
        line-height: 18.5px;
        color: #595959;
      }
    }
  }

  .emoji-list{
    height: 260px;
  }
   .file-loading{
    position: absolute;
    left: 50%;
     top: -56px;
    width: 115px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    background: #494949;
    border-radius: 4px;
    font-size: 14px;
    transform: translateX(-54px);
    .msg-loading{
        vertical-align: middle;
    }
  }
}
</style>
