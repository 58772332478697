<template>
  <div>
    <div
      class="pop-select"
      data-spm="c0403.d0412"
      data-spm-click
      data-spm-expo
      data-spm-desc="访客留言-打开问题类型选择弹窗"
      @click="selectHandle"
    >
      <van-field
        v-bind="$attrs"
        :value="getValue"
        :class="{hasValue: getValue}"
        disabled
      />

      <i class="el-icon-arrow-down"></i>
    </div>
    <van-popup
      v-model="show"
      position="bottom"
      :close-on-click-overlay="false"
    >
      <div
        v-for="(item, index) in optionList"
        :key="item.id"
        class="option-item"
        data-spm="c0403.d0408"
        data-spm-click
        data-spm-expo
        data-spm-desc="访客留言-选择问题类型"
        data-spm-index="{index}"
        @click="onSelect(item)"
      >
        {{ item.name }}
      </div>
      <div
        class="cancel"
        data-spm="c0403.d0413"
        data-spm-click
        data-spm-expo
        data-spm-desc="访客留言-取消选择问题类型"
        @click="show=false"
      >
        取消
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from 'vant'
import { Field } from 'vant'
export default {
  components: {
    VanPopup: Popup,
    VanField: Field
  },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      optionList: Object.freeze([
        { id: 'PRE_SALES', name: '售前服务' },
        { id: 'AFTER_SALES', name: '售后服务' },
        { id: 'COMPLAINT_ADVICE', name: '投诉建议' }]),
      show: false
    }
  },

  computed: {
    getValue () {
      if (!this.value) {
        return ''
      }
      return this.optionList.find(row => {
        return row.id === this.value
      }).name
    }
  },

  methods: {
    selectHandle () {
      this.show = true
    },

    onSelect (item) {
      this.$emit('input', item.id)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pop-select {
  position: relative;
  color: #323233;
  border-radius: 4px;
  background-color: #fff;
  > .el-icon-arrow-down {
    position: absolute;
    top: 8px;
    right: 13px;
    color: #d9d9d9;
    box-shadow: 0px 0px 0px 0px rgba(232, 232, 232, 1);
  }
}

.cancel,
.option-item {
  font-size: 18px;
  font-weight: 400;
  line-height: 50px;
  height: 50px;
  text-align: center;
  color: #888;
  border-top: 7px solid #f5f5f5;
}

.option-item {
  color: #262626;
  border-width: 1px;
}

.hasValue {
  ::v-deep .van-field__control:disabled {
    -webkit-text-fill-color: #323233;
  }
}
</style>
