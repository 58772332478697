import { getTokenApi } from '@/plugins/common/ds-cloud/util.js'
import { getUploadSign } from '@/utils/common.js'
const httpRequestUpload = async ({ file }) => {
  const { api } = getTokenApi()
  let formData = new FormData()
  formData.append('file', file)
  formData.append('sign', getUploadSign())
  const params = formData
  try {
    const { data, success } = await api(params)
    return success ? data : {}
  } catch (error) {
    // eslint-disable-next-line
   return {}
  }
}

export {
  httpRequestUpload
}
