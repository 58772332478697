import { emojiEmun } from './wxEmoji.js'

export const IMG_URL = require('@assets/images/emoji/emoji@2x.png')

export default class Emoji {
  constructor (options) {
    this.init(options)
  }

  init (options) {
    this.emojiEmun = emojiEmun
    this.emojiList = this.initList(options)
  }

  // 初始化样式
  initList (options) {
    let { list } = options
    list = list.map(item => this.emojiEmun[`[${item}]`])
    return list.map((eItem, index) => ({
      ...eItem,
      emojiStyle: {
        ...getEmojiStyle(options, index)
      }
    }))
  }

  /**
   * 根据表情名称返回一个img图片
   * @param {String} name emoji名称，如：[微笑]
   */
  static getImgByName (name) {
    const chooseEmoji = emojiEmun[name]
    if (!(chooseEmoji && chooseEmoji.cn)) return name
    try {
      const imgUrl = require(`@assets/images/emoji/expression_${chooseEmoji.id}@2x.png`)
      return `<img name="emoji" title="${chooseEmoji.cn}" width="24px" height="24px" style="vertical-align: middle;" src="${imgUrl}"/>`
    } catch (error) {
      return name
    }
  }

  /**
   * 将图<img name="[微笑]"/> ==> [微笑]
   * @param {String} name 名称，如：[微笑]
   */
  static getEmojiNameByImg (name) {
    // eslint-disable-next-line
    const reg = /<img .+?name=\"(\[.+?\])\".+?>/g;
    return name.replace(reg, '$1').replace(/<.+?>/g, '')
  }
}

const getEmojiStyle = (emojiOption, index) => {
  let { size, rowNum } = emojiOption
  return {
    display: 'inline-block',
    background: `url(${IMG_URL}) no-repeat`,
    width: `${size}px`,
    height: `${size}px`,
    'background-size': `${rowNum * 100}%`,
    'background-position': `${-size * (index % rowNum)}px ${-size *
      Math.floor(index / rowNum)}px`
  }
}
