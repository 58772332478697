<template>
  <div
    class="chat-window-top"
    :style="style"
  >
    <span class="chat-window-top-title">{{ title }}</span>
    <mk-iconfont
      class="chat-window-top-back"
      icon-class="iconfont-2560775 duero-icon-2560775-fanhui"
      @click="goBack"
    ></mk-iconfont>
    <div class="chat-window-top-operate">
      <slot name="operate"></slot>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@utils/common.js'
export default {
  name: 'ChatWindowTop',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    style () {
      return {
        '--bg-color': this.chatConfig.webDetailInfoVO?.baseSetting?.themeColor || ''
      }
    }
  },
  methods: {
    goBack () {
      if (location === top.location) {
        window.history.go(-1)
      } else {
        const message = JSON.stringify({ type: 'close' })
        EventBus.$emit('closeChatWindow', message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-window-top{
  position: relative;
  height: 41px;
  line-height: 41px;
  padding: 0 15px;
  background-color: var(--bg-color);
  color: #fff;

  &-title{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  &-back{
    &.kf-icon{
      float: left;
      font-size: 18px;
    }
  }

  &-operate{
    float: right;
    font-size: 18px;
  }
}
</style>
