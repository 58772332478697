import { RECEPTIONIST_STATUS } from '@utils/enums/im'
import { nanoid } from 'nanoid'

let chatRecordList = sessionStorage.getItem('chatRecordList')
chatRecordList = (chatRecordList ? JSON.parse(chatRecordList) : []).filter(item => item.isHistory !== true)
chatRecordList.length && chatRecordList.push({ isHistory: true, content: JSON.stringify({ msgType: 'history' }) })

let visitorId = localStorage.getItem('visitorId')
if (!visitorId) {
  visitorId = nanoid()
  localStorage.setItem('visitorId', visitorId)
}

export default {
  state: {
    receptionistStatus: 'online',
    // 是否打开socket
    openSocket: '',
    // 聊天窗口配置
    chatConfig: {},
    // 聊天状态
    chatAction: '',
    // 聊天基本信息
    chatInfo: {
      kfId: '',
      ipAddress: '',
      address: '',
      status: '',
      kfName: '',
      avatar: '',
      conversationId: '', // 会话id
      visitorId: visitorId // 访客id
    },
    // 聊天记录
    chatRecordList: chatRecordList
  },
  getters: {
    csIsOffline: state => {
      return state.receptionistStatus === RECEPTIONIST_STATUS['offline']
    }
  },
  mutations: {
    'SET_RECEPTIONIST_STATUS' (state, data) {
      state.receptionistStatus = data
    },
    'SET_OPEN_SOCKET' (state, data) {
      state.openSocket = data
    },
    'SET_CHAT_CONFIG' (state, data) {
      state.chatConfig = data
    },
    'SET_CHAT_ACTION' (state, data) {
      state.chatAction = data
    },
    'SET_CHAT_INFO' (state, data) {
      state.chatInfo = data
    },
    'SET_CHAT_RECORD_LIST' (state, data) {
      state.chatRecordList = data
      sessionStorage.setItem('chatRecordList', JSON.stringify(data.slice(-100)))
    }
  },
  actions: {
    setReceptionistStatus ({ commit }, params) {
      commit('SET_RECEPTIONIST_STATUS', params)
    },
    setOpenSocket ({ commit }, params) {
      commit('SET_OPEN_SOCKET', params)
    },
    setChatConfig ({ commit }, params) {
      commit('SET_CHAT_CONFIG', params)
    },
    setChatAction ({ commit }, params) {
      commit('SET_CHAT_ACTION', params)
    },
    setChatInfo ({ commit }, params) {
      commit('SET_CHAT_INFO', params)
    },
    setChatRecordList ({ commit }, params) {
      commit('SET_CHAT_RECORD_LIST', params)
    }
  }
}
