<template>
  <div class="send-msg-input kf-emoji">
    <div
      id="input-content"
      inputmode="search"
      :placeholder="customPlaceholder"
      maxlength="500"
      class="input-content"
      :contenteditable="!disabled"
      :value="content"
      :style="{
        minHeight: minHeight+'px',
        maxHeight: maxHeight+'px'
      }"
      @focus="inputFocus"
      @blur="inputBlur"
      @paste.stop.prevent="handlePaste"
      @drop="handleDrop"
      @keydown="handleKeydown"
      @mouseup="handleMouseup"
      @mouseleave="handleMouseleave"
    >
    </div>
    <slot>
      <div
        v-if="isBtnSend"
        class="clearfix operate-btns"
      >
        <div
          :class="[
            'btn',
            'btn-send-msg',
            disabled?'disable':''
          ]"
          :style="sendBtnStyle"
          @click="sendMessage"
        >
          发送
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import Emoji from '../emoji/emoji.js'
import { httpRequestUpload } from '@/plugins/common/upload-img'
import { parseInnerHtml } from './inputDiv'
import { constructorInputShow, ImageMessage } from '@/utils/models/message'
import _debounce from 'lodash/debounce'

export default {
  name: 'SendMsgInput',
  props: {
    isBtnSend: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: Number,
      default: 24
    },
    maxHeight: {
      type: Number,
      default: 100
    },
    maxNum: {
      type: Number,
      default: 500
    },
    message: {
      type: [Array, Object],
      default: () => []
    },
    customPlaceholder: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      scrollTop: 0,
      content: '', // 格式，如：test[微笑]123[图片][消息]
      selectionRange: null // 输入框选中的区域, 文本或输入框本身
    }
  },
  computed: {
    sendBtnStyle () {
      return {
        '--bg-color': this.theme
      }
    }
  },
  watch: {
    disabled: {
      handler: function (flag) {
        if (flag) {
          this.clearInputContent()
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const inputContentDom = document.getElementById('input-content')
      // 是否可用
      if (this.disabled) {
        return
      }
      // 解决重连始终失败导致键盘一直弹出收起
      // 焦点聚焦
      // inputContentDom.focus()
      // 初始化选中的SelectRange
      // this.setInputDivSelectRange()
      // 初始化输入内容
      if (this.message.length) {
        this.setInnerHtml(this.message)
      }
    },
    /**
     * 设置聊天框内容，innerHtml中的显示
     * 外层可通过此方法设置新的内容
     * 1. 设置 selectionRange
     * 2. 聊天框中[表情]，转化为img标签
     * 3. 聊天框中是否选中了文本，若选中文本将被替换成输入内容
     * @param {String} value 设置的值
     */
    setInnerHtml (value) {
      if (this.selectionRange == null) {
        document.getElementById('input-content').focus()
        this.setInputDivSelectRange()
      }

      if (window.getSelection) {
        window.getSelection().removeAllRanges()
        window.getSelection().addRange(this.selectionRange)
      } else {
        this.selectionRange.select()
      }

      // 包含的表情/图片转Dom展示
      value = constructorInputShow(value)

      // 3. 判断是否选中了文本，若选中文本将被替换成输入内容
      if (window.getSelection) {
        const sel = window.getSelection()
        let range
        // IE9 and non-IE
        if (sel.getRangeAt && sel.rangeCount) {
          // 删除选中的文本(内容)
          range = sel.getRangeAt(0) // 获取鼠标选中的文本区域
          range.deleteContents() // 删除选中的文本

          // 创建以输入内容为内容的DocumentFragment
          let elemnet
          if (range.createContextualFragment) {
            elemnet = range.createContextualFragment(value)
          } else {
            elemnet = document.createDocumentFragment()
            const divEl = document.createElement('div')
            divEl.innerHTML = value
            for (let i = 0, len = divEl.children.length; i < len; i++) {
              elemnet.appendChild(divEl.firstChild)
            }
          }
          // 选中文本的位置替换为新输入的内容，并把光标定位到新内容后方
          const lastNode = elemnet.lastChild
          range.insertNode(elemnet)
          range.setStartAfter(lastNode)
          sel.removeAllRanges()
          sel.addRange(range)
        }
      } else if (document.selection && document.selection.type !== 'Control') {
        // IE < 9
        document.selection.createRange().pasteHTML(value)
      }
      // 计算长度，设置内容
      this.setContent()
    },
    /**
     * 计算长度，设置 content。格式：123[微笑]test[大哭][图片][消息]
     * 1. 获取 "input-content" 的innerHtml
     * 2. 字数统计：替换<img name="[微笑]"/> ==> [表情]/[图片]
     * 3. 最大长度判断，超过则截取
     */
    setContent () {
      // setTimeout解决keydown之后无法拿到最新值的问题
      setTimeout(() => {
        const el = document.getElementById('input-content')
        let htmlStr = el.innerHTML
        // eslint-disable-next-line
        let tempContent = htmlStr.replace(/<img name="emoji".+?title=\"(\[.+?\])\".+?[^>]*>/ig, '$1').replace(/<img name="(\[图片\])".+?[^>]*>/ig, '$1')
        // eslint-disable-next-line
        // console.log(`content: ${tempContent}`);
        if (tempContent.length > this.maxNum) {
          el.innerHTML = ''
          const value = tempContent.substr(0, this.maxNum)
          this.$notify({ type: 'danger', message: `长度超过${this.maxNum}` })
          this.setInnerHtml(value)
          this.$nextTick(() => {
            el.scrollTop = el.scrollHeight
          })
        }
        this.content = tempContent
      }, 17)
    },
    // 监听键盘输入
    handleKeydown (e) {
      if (e.keyCode === 13) {
        // 阻止回车默认换行
        e.preventDefault()
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand('insertText', false, '\n')
          const el = document.getElementById('input-content')
          el.scrollTo(0, el.scrollHeight)
        } else {
          this.sendMessage()
          return
        }
      }
      this.setContent()
      this.setInputDivSelectRange()
    },
    // 在元素上松开鼠标按键（左、右键）
    handleMouseup: _debounce(function (e) {
      this.setInputDivSelectRange()
    }, 200),
    // 监听粘贴操作
    async handlePaste (e) {
      let isImage = false
      const clipboardData = e.clipboardData || window.clipboardData
      if (clipboardData && clipboardData.items.length > 0) {
        // 1.上传图片
        for (let i = 0; i < clipboardData.items.length; i++) {
          const item = clipboardData.items[i]
          if (item.kind === 'file' && item.type.indexOf('image') >= 0) {
            const file = item.getAsFile()
            const { linkUrl } = await httpRequestUpload({ file })
            const imgMsg = new ImageMessage({ url: linkUrl })
            this.setInnerHtml(imgMsg)
            isImage = true
          }
        }
        // 2.非图片，粘贴纯文本, 其他类型
        if (!isImage) {
          const str = clipboardData.getData('text/plain')
          if (!str) return
          const span = document.createElement('span')
          span.innerHTML = str
          const txt = this.safeMsg(span.innerText)
          const txtMsg = this.changeAllEmojiToImg(txt)
          this.setInnerHtml(txtMsg)
        }
        this.$nextTick(() => {
          const el = document.getElementById('input-content')
          el.scrollTop = el.scrollHeight
        })
      }
    },
    /**
     * 包含的表情 emoji 图片
     */
    changeAllEmojiToImg (value) {
      if (!value) { return }
      return value.replace(/\[.+?\]/g, function (v) {
        return Emoji.getImgByName(v)
      })
    },
    // 当鼠标移出某元素时触发
    handleMouseleave (e) {
      this.setInputDivSelectRange()
    },
    // 拖拽到输入框
    handleDrop (e) {
      this.setInputDivSelectRange()
    },
    /**
     * window.getSelection返回一个 Selection对象
     * 表示用户选择的文本范围或光标的当前位置
     */
    setInputDivSelectRange () {
      if (window.getSelection && window.getSelection().rangeCount > 0) {
        const sr = window.getSelection().getRangeAt(0)
        const srContainer = sr.commonAncestorContainer
        // 选中了输入框内的文本
        if (
          srContainer.nodeName === '#text' &&
          srContainer.parentElement &&
          srContainer.parentElement.id === 'input-content'
        ) {
          this.selectionRange = sr
        }
        // 选中了输入框
        if (srContainer.id === 'input-content') {
          this.selectionRange = sr
        }
      }
    },
    sendMessage () {
      // 当仅输入空格或换行时，都判断为空
      const trimContent = this.content.replace(/(&nbsp;|<\/?div>|<br>)/ig, ' ').trim()
      if (!trimContent.length) {
        this.$notify({ type: 'warning', message: '请输入发送消息' })
        return
      }
      let htmlStr = document.getElementById('input-content').innerHTML
      htmlStr = htmlStr.replace(/<div><br><\/div>/g, '')
        .replace(/<[/]div>/g, '')
        .replace(/<div>/g, '<br>')
      htmlStr = this.safeMsg(htmlStr)
      let sendData = parseInnerHtml(htmlStr)
      this.$emit('send-message', sendData)
    },
    clearInputContent () {
      this.content = ''
      document.getElementById('input-content').innerHTML = ''
    },
    safeMsg (str) {
      return str.replace(/\n/g, '')
        .replace(/\r/g, '')
    },
    inputFocus () {
      this.scrollTop = document.scrollingElement.scrollTop
    },
    inputBlur () {
      document.scrollingElement.scrollTo(0, this.scrollTop)
    }
  }
}
</script>

<style lang='scss' scoped>
.send-msg-input {

  .input-content {
    // position: relative;
    font-size: 14px;
    color: #262626;
    padding: 0 14px;
    line-height: 20px;
    resize: none;
    overflow-y: auto;
    overflow-x: hidden;
    outline: 0;
    height: auto;
    user-select: text;

    // placeholder
    &:empty::before{
      display: block;
      content: attr(placeholder);
      color: #ccc;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:focus::before{
      content: '';
    }

    // 滚动条样式修改
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 999px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      background-color: #e8e8e8;
      box-shadow: 1px 1px 5px #e8e8e8 inset;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  .btn-send-msg {
    font-size: 14px;
    color: #fff;
    line-height: 20px;
    background-color: var(--bg-color);
    border-radius: 2px;
    padding: 4px 16px;
    user-select: none;

    &.disable {
      background-color: #bfbfbf;
    }
  }
}

.clearfix{
  clear: both;
}

.operate-btns{
  text-align: right;
  padding: 4px 0 10px;

  .btn{
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>
