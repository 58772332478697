<template>
  <div
    class="chat-window-side"
    :style="style"
  >
    <div
      v-for="(content, index) in contentList"
      :key="index"
      class="side-info"
    >
      <p class="side-info-title">
        {{ content.menuName }}
      </p>
      <div class="side-info-content">
        <span v-if="content.menuType === 'content'">{{ content.customize }}</span>
        <a
          v-if="content.menuType === 'url'"
          :href="content.menuUrl"
          target="_blank"
        >{{ content.menuUrl }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import ChatSideBg from '@/assets/images/chat-side-bg.png'
export default {
  name: 'ChatWindowSide',
  computed: {
    style () {
      return {
        '--bg-image': `url(${ChatSideBg})`
      }
    },
    chatConfig () {
      return this.$store.state.chatConfig
    },
    contentList () {
      const sidebarContentList = this.chatConfig.webDetailInfoVO?.sidebarSetting?.sidebarContentList || []
      return sidebarContentList.filter(item => item.status === '0')
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-window-side{
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: var(--bg-image);
  background-size: 100% auto;
  background-position: bottom left;
  background-repeat: no-repeat;
  padding: 15px 14px;
  overflow: auto;
  box-sizing: border-box;

  .side-info{
    width: 172px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;
    background-color: #f5f5f5;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &-title{
      font-size: 14px;
      line-height: 20px;
      color: #262626;
      margin-bottom: 9px;
    }

    &-content{
      font-size: 13px;
      line-height: 18px;
      word-break: break-all;

      span{
        color: #595959;
      }

      a{
        color: #4d78bd;
      }
    }
  }
}
</style>
