<template>
  <div
    class="chat-window-top"
    :style="style"
  >
    <div class="chat-window-top-user">
      <div
        class="chat-window-top-user-avatar"
        :class="{'no-border': userAvatar.type === 'default'}"
      >
        <img :src="userAvatar.url" />
      </div>
      <span class="chat-window-top-user-info">{{ chatInfo.kfName || '在线客服' }}</span>
    </div>
    <div class="chat-window-top-operate">
      <mk-iconfont
        style="font-size: 20px;
              position: absolute;
              top: 17px;"
        :icon-class="audioOpen ? 'iconfont-2492785 duero-icon-2492785-shengyin-dakai' : 'iconfont-2492785 duero-icon-2492785-shengyin-guanbi'"
        @click="switchSound"
      ></mk-iconfont>
      <mk-iconfont
        v-if="platform==='web-window'"
        style="font-size: 18px;"
        icon-class="iconfont-2492785 duero-icon-2492785-fangda"
        @click="toFullScreenChat"
      ></mk-iconfont>
      <mk-iconfont
        style="font-size: 16px;
              position: absolute;
              right: 16px;
              top: 18px;"
        icon-class="iconfont-2492785 duero-icon-2492785-guanbi01"
        @click="closeChat"
      ></mk-iconfont>
    </div>
  </div>
</template>

<script>
import avatar from '@/assets/images/avatar.png'
import ChatTopBg from '@/assets/images/chat-top-bg.png'
import ChatTopBgLong from '@/assets/images/chat-top-bg-long.png'
import Sound from '@/assets/audio/sound.mp3'
import { EventBus } from '@utils/common.js'
export default {
  name: 'ChatWindowTop',
  props: {
    platform: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      audioOpen: true
    }
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    chatInfo () {
      return this.$store.state.chatInfo
    },
    style () {
      return {
        '--bg-color': this.chatConfig.webDetailInfoVO?.baseSetting?.themeColor || '',
        '--bg-image': `url(${this.platform === 'web-window' ? ChatTopBg : ChatTopBgLong})`
      }
    },
    userAvatar () {
      return {
        url: this.chatInfo.avatar || avatar,
        type: this.chatInfo.avatar ? 'user' : 'default'
      }
    }
  },
  created () {
    EventBus.$on('newMessage', this.playAudio)
  },
  beforeDestroy () {
    EventBus.$off('newMessage', this.playAudio)
  },
  methods: {
    closeChat () {
      if (this.platform === 'web-window') {
        // 窗口模式点击关闭
        const message = JSON.stringify({ type: 'close' })
        EventBus.$emit('closeChatWindow', message)
      } else {
        // 全屏模式点击关闭
        EventBus.$emit('closeChatSocket')
      }
      const spmCloseChat = 'c0402.d0419'/** data-spm-desc: 访客聊天框-窗口模式关闭 **/
      window.Monitor?.sendSPM(spmCloseChat)
    },
    switchSound () {
      this.audioOpen = !this.audioOpen
      if (!this.audioOpen) {
        const audios = document.querySelectorAll('.chat-message-audio')
        for (let audio of audios) {
          document.body.removeChild(audio)
        }
      }
      const spmSwitchSound = 'c0406.d0422'/** data-spm-desc: 访客WEB聊天框-开启/关闭声音 **/
      window.Monitor?.sendSPM(spmSwitchSound)
    },
    toFullScreenChat () {
      EventBus.$emit('closeChatSocket')
      const queryString = window.location.href.split('?')[1]
      const openUrl = `/chat-fullscreen${queryString ? '?' + queryString : ''}`
      window.open(openUrl)
      const spmToFullScreenChat = 'c0402.d0405'/** data-spm-desc: 访客聊天框-切换全屏 **/
      window.Monitor?.sendSPM(spmToFullScreenChat)
    },
    playAudio () {
      if (this.audioOpen) {
        let audio = document.createElement('audio')
        audio.src = Sound
        audio.autoplay = true
        audio.className = 'chat-message-audio'
        audio.style.display = 'none'
        audio.addEventListener('ended', () => {
          document.body.removeChild(audio)
        })
        document.body.appendChild(audio)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-window-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  background-color: var(--bg-color);
  background-image: var(--bg-image);
  background-size: auto 100%;
  background-position: top right;
  background-repeat: no-repeat;

  &-user{
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 20px;

    &-avatar{
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
      border: 2px solid #fff;
      overflow: hidden;
      box-sizing: border-box;

      &.no-border{
        border: 0;
      }

      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &-info{
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-operate{
    line-height: 1;

    .kf-icon {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 12px;
      }
       &:nth-last-child(3){
          right: 59px;
      }
      &:nth-last-child(2){
        margin-right: 28px;
        right: 15px;
      }
    }
  }
}
</style>
