/*
 * @Author: huangzq
 * @Date: 2020-11-12 11:56:13
 * @LastEditors: huangzq
 * @LastEditTime: 2020-11-13 11:21:33
 * @Description:
 */
const httpCode:any = {
  401: '登录失效，请重新登录',
  404: '请求的数据接口不存在！',
  408: '请求超时',
  500: '后端童鞋开小差了！', // 服务器内部错误
  10001: '未知错误！',
  10002: '网络错误，请检查网络是否正常！'
}

export default httpCode
