import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/index.js'
import '@/plugins/vue-instance'
import '@/plugins/env'
import 'normalize.css'
import '@/assets/styles/reset.scss'
// import VConsole from 'vconsole'

// import '../dist/wc/kf-test'

import MkIconfont from '@/components/mk-iconfont'
Vue.use(MkIconfont, {
  scriptUrl: ['//at.alicdn.com/t/font_2492785_2kau9unahka.css', '//at.alicdn.com/t/font_2560775_ti1w1i1amt.css']
})

// const vconsole = new VConsole()
// export default vconsole

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
