<template>
  <div class="chat-web-fullscreen">
    <div class="chat-window-container">
      <message-window
        v-if="onlyMessage"
        :theme="theme"
        :show-close="false"
      />
      <chat-window
        v-else
        platform="web-fullscreen"
      />
    </div>
  </div>
</template>

<script>
import ChatWindow from '@components/chat-window'

export default {
  name: 'ChatWebFullScreen',
  components: {
    ChatWindow
  },
  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    theme () {
      return this.chatConfig.webDetailInfoVO?.baseSetting?.themeColor || undefined
    },
    onlyMessage () {
      return this.$store.state.openSocket === false && this.chatConfig.leaveMessage?.isEnable
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:math";

.chat-web-fullscreen{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .chat-window-container{
    width: percentage(math.div(800, 1440));
    height: percentage(math.div(772, 1031));
    max-width: 800px;
    min-width: 700px;
    max-height: 772px;
    min-height: 500px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.15);
  }
}
</style>
