<template>
  <div class="chat-emoji">
    <div
      ref="chatEmoji"
      class="chat-emoji-list"
      @click="selectEmoji"
    >
      <a
        v-for="(item, index) in emojiList"
        :key="index"
        :style="getStyles(item)"
        :title="item.cn"
        :name="item.cn"
        :class="['emoji-item', `emoji-${index}`]"
      >
      </a>
    </div>
    <!-- <div class="chat-emoji-tabs">
      <div
        v-for="tab in emojiTabs[activeTab]"
        :key="tab.type"
        class="emoji-tab"
      >
        <img
          :src="tab.icon"
          alt="icon"
        >
      </div>
    </div> -->
  </div>
</template>

<script>
import Emoji from './emoji.js'
import { loadImage } from '@/utils/common'

const IMG_URL = require('@assets/images/emoji/emoji@2x.png')
export default {
  name: 'EmojiList',
  props: {
    size: {
      type: Number,
      default: 24
    },
    rowNum: {
      type: Number,
      default: 12
    },
    // 每行表情缩减数
    reduceNum: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      emojiList: [],
      emojiTabs: {
        wxEmoji: [
          { type: 'wxEmoji', icon: require('@assets/images/emoji/default-icon@2x.png') }
        ]
      },
      activeTab: 'wxEmoji',
      showList: [
        '微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒',
        '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '愉快', '白眼', '傲慢', '饥饿', '困', '惊恐',
        '奋斗', '咒骂', '疑问', '嘘', '晕', '疯了', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻',
        '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜',
        '咖啡', '饭', '猪头', '玫瑰', '凋谢', '嘴唇', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀',
        '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引',
        '拳头', '差劲',
        '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈'
      ],
      margin: ''
    }
  },
  created () {
    loadImage(IMG_URL)
    this.emoji = new Emoji({
      size: this.size,
      list: this.showList,
      rowNum: this.rowNum
    })
    this.emojiList = Object.freeze(this.emoji.emojiList)
  },
  mounted () {
    this.$nextTick(() => {
      const el = this.$refs.chatEmoji
      const width = el.clientWidth
      // 设置margin来实现flex布局下的栅格
      this.margin = `0 ${(width % this.size + this.reduceNum * this.size) / (parseInt(width / this.size) - this.reduceNum) / 2}px`
    })
  },
  methods: {
    selectEmoji (e) {
      const emojiName = e.target.getAttribute('name')
      if (!emojiName) { return }
      this.$emit('call-select-emoji', emojiName)
    },
    getStyles (item) {
      return {
        ...item.emojiStyle,
        margin: this.margin
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-emoji{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  user-select: none;

  &-list{
    overflow-y: auto;
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 24px;
    margin: 10px 8px 0 10px;

    // 滚动条样式修改
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 999px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      background-color: #e8e8e8;
      box-shadow: 1px 1px 5px #e8e8e8 inset;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  &-tabs{
    height: 40px;
    background-color: #f5f6f7;

    .emoji-tab {
      width: 40px;
      height: 40px;
      background-color: #fff;

      img {
        width: 18px;
        height: 18px;
        margin: 11px;
      }

      &:hover {
        background: #e8e8e8;
      }
    }
  }
}
</style>
