
import Vue from 'vue'

import { Upload, Button, Form, FormItem, Select, Option, Input, Drawer, Dialog, Image } from 'element-ui'
import { Notify } from 'vant'

export default function () {
  Vue.use(Upload)
  Vue.use(Button)
  Vue.use(Form)
  Vue.use(FormItem)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Input)
  // Vue.use(Scrollbar)
  Vue.use(Drawer)
  Vue.use(Dialog)
  Vue.use(Image)
  Vue.use(Notify)
}
