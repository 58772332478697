<template>
  <div class="chat-window-content">
    <div
      infinite-scroll-immediate="scrollImmediate"
      infinite-scroll-trigger-type="scrollUp"
      infinite-scroll-distance="0"
      class="chat-record"
    >
      <div
        v-for="(item,idx) in msgList"
        :key="idx"
        class="chat-record-list"
        :class="item.fromUserName ? 'has-name' : 'no-name'"
      >
        <template v-if="DOC_TYPES_ALL.includes(item.content.msgType)">
          <!-- 隐藏区域（时间，历史消息等） -->
          <template>
            <div class="chat-record-time-section">
              <div
                v-if="item.content.msgType === 'history'"
                class="history-tip"
              >
                <span class="history-tip-left"></span>
                <span class="history-tip-text">以上是历史消息</span>
                <span class="history-tip-right"></span>
              </div>
              <div
                class="time-tip"
                :class="{'time-tip-hidden': idx && idx === msgList.length-1}"
              >
                {{ calcTimeDiff(item.sendTime, idx) }}
              </div>
            </div>
          </template>

          <div
            v-if="item.content.msgType !== 'history'"
            :id="item.sendTime"
            :class="['chat-record-item', item.isCustomerSender ? 'is-customer':'is-service' ,]"
          >
            <template v-if="!item.withdraw">
              <!-- 消息内容 -->
              <div
                class="chat-record-item-content"
                :class="{'isResetBg':DOC_TYPES.includes(item.content.msgType) || item.content.msgType === 'news', 'limit-img':['image'].includes(item.content.msgType), 'limit-width':[...DOC_TYPES, 'image'].includes(item.content.msgType)}"
              >
                <!-- 引用消息体 -->
                <chat-quote
                  v-if="item.content && item.content.subContent && item.content.subContent.type==='quote'"
                  :msg-data="item.content.subContent"
                ></chat-quote>

                <div
                  v-if="item.content.msgType === 'text'"
                  v-html="getTextHtml(item.content.data)"
                />
                <el-image
                  v-else-if="item.content.msgType === 'image'"
                  :src="item.content.data"
                  lazy
                  :preview-src-list="imgList"
                  @load="handleImgLoad"
                />

                <!-- 文件类型 -->
                <template v-else-if="DOC_TYPES.includes(item.content.msgType)">
                  <div
                    class="doc-region"
                    @click="handleOpenFile(item.content)"
                  >
                    <div class="doc-region-left">
                      <div class="doc-title">
                        {{ item.content.data.title }}
                      </div>
                      <div class="doc-size">
                        {{ filterSize(item.content.data.size) }}
                      </div>
                    </div>

                    <div class="doc-region-right">
                      <img
                        :src="DOC_ICONS[item.content.msgType]"
                        class="doc-img"
                        :alt="item.content.data.title"
                      >
                    </div>
                  </div>
                </template>

                <!-- 超链接，卡片 -->
                <template v-else-if="item.content.msgType === 'news'">
                  <ChatCard :card-info="item.content" />
                </template>

                <template v-else>
                  暂不支持类型消息
                </template>

                <div class="time-and-name">
                  <span
                    class="name"
                  >{{ item.fromUserName }}</span>
                </div>
              </div>
            </template>

            <!-- 撤回消息 -->
            <div
              v-if="item.withdraw"
              class="revert-tip"
            >
              <span>对方撤回了一条消息 </span>
            </div>

            <!-- 消息状态 -->
            <template v-if="item.isCustomerSender && !item.withdraw">
              <mk-icon-loading
                v-if="item.loading"
                class="msg-loading"
              />

              <template v-else>
                <mk-iconfont
                  v-if="!item.success"
                  class="msg-failed"
                  icon-class="iconfont-2492785 duero-icon-2492785-kf_fasongshibai"
                />

                <mk-iconfont
                  v-else-if="item.isRead"
                  icon-class="iconfont-2492785 duero-icon-2492785-weidu"
                  class="msg-read"
                />
                <mk-iconfont
                  v-else-if="!item.isRead"
                  icon-class="iconfont-2492785 duero-icon-2492785-yidu"
                  class="msg-unread"
                />
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MkIconLoading from '@components/mk-icon-loading'
import infiniteScroll from '@plugins/directive/infinite-scroll'

import Emoji from '@components/mk-im/emoji/emoji'
import ChatQuote from '@components/chat-window/components/chat-quote'
import ChatCard from '@components/chat-window/components/chat-card'

import { EventBus, transferWetChatTime, chatTime, filterSize } from '@/utils/common'
import { DOC_ICONS, DOC_TYPES } from '@/utils/enums/im'
import { mapState } from 'vuex'
import axios from 'axios'
import { JAVACHATSERVICE } from '@/api'

const fiveSecondsInterval = 300000 // 5分钟间隔
let lastShowTimeStamp = 0 // 记录上次展示时间节点 。下一个时间 > 5分钟，则设置为新的展示时间节点
export default {
  name: 'ChatWindowContent',

  components: {
    MkIconLoading,
    ChatQuote,
    ChatCard
  },

  directives: {
    infiniteScroll
  },

  data () {
    return {
      scrollImmediate: false, // 是否立即执行加载方法
      scrollHeight: 0,
      msgList: [],
      enterTime: '', // 进入会话的时间戳
      DOC_TYPES_ALL: ['doc', 'pdf', 'ppt', 'excel', 'txt', 'video', 'audio', 'text', 'image', 'news', 'history'],
      DOC_TYPES, // 文档类型
      DOC_ICONS
    }
  },

  computed: {
    ...mapState({
      csNickName: state => state.csNickName
    }),

    imgList () {
      return this.msgList.filter(item => item.content.msgType === 'image').map(item => item.content.data)
    },
    chatData () {
      return this.$store.state.chatRecordList
    },
    chatConfig () {
      return this.$store.state.chatConfig
    },
    chatInfo () {
      return this.$store.state.chatInfo
    },

    getTextHtml () {
      return val => {
        if (val) {
          return val.replace(/\[.+?\]/g, function (v) {
            return Emoji.getImgByName(v)
          })
        }

        return ''
      }
    }
  },

  watch: {
    chatData: {
      immediate: true,
      handler (val) {
        this.handleChatChange(val)
      }
    }
  },

  created () {
    // 获取历史会话接口
    // this.getHistoryChatRecord()
    EventBus.$on('scrollChatContent', this.updateScrollTop)
  },

  beforeDestroy () {
    EventBus.$off('scrollChatContent', this.updateScrollTop)
  },

  methods: {
    async getHistoryChatRecord () {
      await this.$fetchHelper(JAVACHATSERVICE.MessageVisitorRecordApi.apiMessageVisitorRecordGet, { account: this.chatConfig.account, userId: this.chatInfo.visitorId, pageIndex: 1, pageSize: 50 })
    },
    /**
     * 处理会话数据
     */
    handleChatChange (message) {
      this.enterTime = Date.now()
      this.msgList = message.map((item) => this.formatMsg(item))
      this.updateScrollTop()
    },

    formatMsg (msg) {
      const isCustomerSender = msg.fromUserType === 2 // 发送者角色 1 客服 2 用户 3 系统

      return {
        ...msg,
        formatTime: chatTime(msg.sendTime),
        isCustomerSender,
        fromUserHeadImg: msg.fromUserHeadImg || '',
        content: JSON.parse(msg.content)
      }
    },

    // 计算时间差
    calcTimeDiff (stepTime, index) {
      if (index === 0 || (stepTime - lastShowTimeStamp) > fiveSecondsInterval) {
        lastShowTimeStamp = stepTime
        return transferWetChatTime(stepTime)
      } else {
        return ''
      }
    },

    handleImgLoad () {
      const $chatRecord = this.$el.querySelector('.chat-record')
      $chatRecord.scrollTop = $chatRecord.scrollTop + $chatRecord.scrollHeight - this.scrollHeight
      this.scrollHeight = $chatRecord.scrollHeight
    },

    updateScrollTop () {
      setTimeout(() => {
        const $chatRecord = this.$el.querySelector('.chat-record')
        $chatRecord.scrollTop = $chatRecord.scrollHeight

        // 记录滚动位置以便历史聊天记录加载后需滚动至上页位置
        this.scrollHeight = $chatRecord.scrollHeight
      }, 40)
    },

    // 模拟双击打开文件
    handleOpenFile (obj) {
      let _msgType = obj.msgType || obj.msgtype
      if (this.DOC_TYPES.includes(_msgType)) {
        this.createALink(obj.data.url)
      } else if (_msgType === 'news') {
        this.createALink(obj.news.link)
      }
    },
    // 创建a 标签
    createALink (url) {
      if (!url) return
      let link = document.createElement('a') // 创建a标签
      link.style.display = 'none'
      link.href = url
      link.setAttribute('target', '_blank')

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    filterSize (size) {
      return filterSize(size)
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollbar-y-hide {
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;

  /* IE 10+ */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Chrome Safari */
}

.chat-window-content {
  flex: 1;
  padding: 0 14px;
  overflow: hidden;

  .chat-record {
    height: 100%;

    .chat-operate-wrap {
      position: absolute;
      min-width: 82px;
    }

    @extend .scrollbar-y-hide;

    &-loading {
      display: flex;
      justify-content: center;
      margin: 16px 0;
    }

    &-list {
      margin-bottom: 33px;

      &.no-name {
        margin-bottom: 16px;

        & + .has-name {
          margin-top: 33px;
        }
      }

      &.has-name{
        &+ .no-name{
          margin-top: -17px;
        }
      }
    }

    &-item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      &-avatar {
        border-radius: 6px;
        width: 36px;
        height: 36px;
        margin-right: 10px;
      }

      &-content {
        padding: 10px 8px;
        font-size: 14px;
        border-radius: 0 6px 6px 6px;
        cursor: pointer;
        font-size: 13px;
        line-height: 20px;
        color: #262626;
        max-width: 350px;
        position: relative;
        word-break: break-all;

        &.limit-width.limit-img{
          min-width: 10%;
          max-width: 50%;
          background: transparent !important;
          padding: 0;
          border: 0px;
          width: auto;
          line-height: 0;
          margin: 0;
          border-color: transparent!important;
        }
        &.limit-width{
          width: 60%;
          border: 1px solid #ececec;
          padding: 10px;
          background: #fff!important;
          border-radius: 6px 6px 6px 6px!important;
        }
        .time-and-name {
          line-height: 17px;
          color: #a1a1a1;
          font-size: 12px;
          position: absolute;
          left: 0;
          top: -17px;
          white-space: nowrap;

          .name {
            margin-left: 6px;
            margin-bottom: 2px;
          }
        }
      }

      .msg-loading,
      .msg-failed {
        align-self: center;
        font-size: 12px;
        margin-right: 4px;
        margin-bottom: 4px;
      }

      .msg-failed {
        color: #ec0a0e;
      }

      .msg-read,
      .msg-unread {
        color: #adb7c9;
        align-self: flex-end;
        font-size: 12px;
        margin-right: 4px;
        margin-bottom: 4px;
      }

      .chat-record-item {
        position: relative;
      }

      &.is-customer {
        flex-direction: row-reverse;

        .chat-record-item-content {
          background: #D3ECFD;
          border-radius: 6px 0px 6px 6px;
        }

        &.isResetBg {
          width: 260px;
          background: #fff;
          border: 1px solid #ebebeb;
          box-sizing: border-box;
        }

        .time-and-name {
          right: 0;
          text-align: right;
          left: unset;
        }
      }

      &.is-service {

        .chat-record-item-avatar {
          margin-right: 0;
          margin-left: 10px;
        }

        .chat-record-item-content {
          border: 1px solid #f5f5f5;
          background: #FFFFFF;
          margin-right: 0;

          &.isResetBg {
            width: 260px;
            background: #fff;
            border: 1px solid #ebebeb;
          }

          &.isVideo {
            background: transparent;
          }

          .time-and-name {
            left: 0;
            right: unset;
            text-align: left;
          }

        }
      }

      .doc-region {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-left {
          margin-right: 14px;
          flex-grow: 1;

          .doc-title {
            font-size: 14px;
            color: #3e4863;
            margin-bottom: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .doc-size {
            color: #8798ad;
            font-size: 12px;
          }
        }

        &-right {
          display: flex;
          margin-top: 2px;

          .doc-img {
            width: 40px;
            height: 40px;
            border-radius: 2px;
          }
        }
      }

      .revert-tip {
        font-weight: 400;
        color: #888;
        font-size: 13px;
        text-align: center;
        margin: 20px auto;

        .revert-tip-again {
          color: #576b95;
        }
      }
    }

    &-time-section {
      font-size: 12px;
      color: #c3c3c3;
      text-align: center;

      .time-tip {
        margin-top: 33px;
        margin-bottom: 33px;

        &.time-tip-hidden {
          display: none;
        }
      }

      .history-tip {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 8px 0;

        &-left,
        &-right {
          width: calc(50% - 108.5px);
          height: 1px;
          display: inline-block;
          background: #eee;
        }

        &-text {
          width: 86px;
          margin-left: 16px;
          margin-right: 16px;
          display: inline-block;
        }

        &-left {
          color: #fff;
        }

        &-right {
          color: #fff;
        }
      }
    }
  }
}
</style>
