<template>
  <div
    class="content-box"
  >
    <!-- 顶部导航栏 -->
    <div
      class="top"
      :style="style"
    >
      <mk-iconfont
        style="font-size:18px;"
        icon-class="back iconfont-2560775 duero-icon-2560775-fanhui"
        data-spm="c0403.d0411"
        data-spm-click
        data-spm-expo
        data-spm-desc="访客留言-返回上一级"
        @click.native="goBack"
      ></mk-iconfont>
      <span>请您留言</span>
    </div>
    <!-- 内容区域 -->
    <div class="content">
      <div class="content-tip">
        您好，现在是非工作时间，目前没有人工客服，您可以留下您的联系方式和公司名称。
      </div>
      <!-- 表单 -->
      <van-form
        ref="form"
        :show-error="false"
        :show-error-message="false"
        @submit="onSubmit"
      >
        <div class="item-lable">
          问题类型
        </div>
        <div :class="{err: rules.includes('leaveMessageType')}">
          <pop-select
            v-model="form.leaveMessageType"
            name="leaveMessageType"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择问题类型' }]"
            @input="singleValidate('leaveMessageType')"
          />
          <div class="item-error">
            请选择问题类型
          </div>
        </div>

        <div class="item-lable">
          公司名称
        </div>
        <div :class="{err: rules.includes('messageAccountName')}">
          <van-field
            v-model="form.messageAccountName"
            label=""
            placeholder="请输入"
            name="messageAccountName"
            :rules="[{ required: true, message: '请输入公司名称' }]"
            @input="singleValidate('messageAccountName')"
          />
          <div class="item-error">
            请输入公司名称
          </div>
        </div>

        <div class="item-lable">
          问题描述
        </div>
        <div :class="{err: rules.includes('questionDesc')}">
          <van-field
            v-model="form.questionDesc"
            placeholder="请输入"
            type="textarea"
            label=""
            name="questionDesc"
            :rules="[{ required: true, message: '请输入问题描述' }]"
            @input="singleValidate('questionDesc')"
          />
          <div class="item-error">
            请输入问题描述
          </div>
        </div>

        <div class="item-lable">
          手机号
        </div>
        <div :class="{err: rules.includes('phone')}">
          <van-field
            v-model="form.phone"
            placeholder="请输入"
            type="tel"
            label=""
            name="phone"
            :rules="[
              { required: true, message: '请输入手机号码' },
              { validator, message: '请输入正确的手机号' }
            ]"
            @input="singleValidate('phone')"
          />
          <div class="item-error">
            {{ errMessage.phone }}
          </div>
        </div>
      </van-form>
    </div>
    <!-- 底部 -->
    <div
      class="bottom"
      :style="style"
      @click="onSubmit"
    >
      提交留言
    </div>
  </div>
</template>

<script>
import PopSelect from './components/select/index.vue'
import { EventBus } from '@utils/common.js'
import { Form, Field } from 'vant'
export default {
  name: 'LeaveMessage',
  components: {
    PopSelect,
    VanForm: Form,
    VanField: Field
  },

  data () {
    return {
      form: {
        leaveMessageType: '',
        messageAccountName: '',
        questionDesc: '',
        phone: ''
      },
      rules: [],
      errMessage: {}
    }
  },

  computed: {
    chatConfig () {
      return this.$store.state.chatConfig
    },
    chatInfo () {
      return this.$store.state.chatInfo
    },
    style () {
      return {
        '--bg-color': this.chatConfig.webDetailInfoVO?.baseSetting?.themeColor || ''
      }
    }
  },

  watch: {
    'form.type': {
      handler: function () {
        this.singleValidate('type')
      }
    }
  },

  methods: {
    validator (val) {
      return /^1[3-9]\d{9}$/.test(val)
    },

    onSubmit () {
      const spmSubmit = 'c0403.d0420'/** data-spm-desc: 访客留言-提交留言 **/
      window.Monitor?.sendSPM(spmSubmit)

      this.$refs.form.validate()
        .then(() => {
          const params = {
            conversationId: this.chatInfo.conversationId || '',
            address: this.chatInfo.address,
            channel: 'H5',
            account: this.chatConfig.account,
            ipAddress: this.chatInfo.visitorId,
            ...this.form
          }
          const data = this.$fetchHelper(JAVACHATSERVICE.LeaveMessageAddLeaveMessageApi.apiLeaveMessageAddLeaveMessagePost, params)
          data.then(() => {
            this.$notify({ type: 'success', message: '留言成功' })
            this.goBack()
          })
        }).catch(err => {
          this.rules = []
          err.forEach(row => {
            this.rules.push(row.name)
            this.$set(this.errMessage, row.name, row.message)
          })
        })
    },

    singleValidate (name) {
      const index = this.rules.indexOf(name)
      this.$refs.form.validate(name)
        .then(() => {
          if (index >= 0) {
            this.rules.splice(index, 1)
          }
        })
        .catch(err => {
          if (index < 0) {
            this.rules.push(err.name)
          }
          this.$set(this.errMessage, err.name, err.message)
        })
    },

    goBack () {
      if (this.$route.query.fromChatWindow) {
        this.$router.back()
      } else {
        if (location === top.location) {
          window.history.go(-1)
        } else {
          const message = JSON.stringify({ type: 'close' })
          EventBus.$emit('closeChatWindow', message)
        }
      }
      const goBack = '访客留言-返回'
      window.Monitor?.sendSPM(goBack)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;

  > .top {
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    height: 41px;
    line-height: 41px;
    padding: 0 15px;
    text-align: center;
    color: #fff;
    background-color: var(--bg-color);
    position: relative;
    > .back {
      position: absolute;
      left: 15px;
      padding-right: 10px;
    }
  }
  > .content {
    overflow: auto;
    flex: 1;
    padding: 15px;
    background: #ebedf0;
    > .content-tip {
      background-color: #dde6f4;
      padding: 10px;
      border-radius: 4px;
      color: #4d78bd;
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 15px;
    }
  }
  > .bottom {
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    height: 36px;
    margin: 12px 15px;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    background: var(--bg-color);
    box-shadow: 0px 1px 0px 0px rgba(245, 245, 245, 1);
    cursor: pointer;
    user-select: none;
  }
}
.item-lable {
  font-size: 13px;
  font-weight: 400;
  color: #262626;
  line-height: 20px;
  margin-bottom: 8px;
  &::before {
    margin-right: 4px;
    content: '*';
    color: #e62412;
  }
}
.item-error {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  visibility: hidden;
  color: #e02020;
}
::v-deep .van-field {
  padding: 3px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  &.van-field--min-height {
    min-height: 80px;
  }
}
.err {
  .item-error {
    visibility: visible;
  }
  ::v-deep .van-field {
    border-color: #e62412;
  }
}
::v-deep .van-cell::after,
::v-deep .van-cell__value::after {
  border: none;
}
</style>
