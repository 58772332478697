/*
 * @Author: huangzq
 * @Date: 2020-11-13 15:26:25
 * @LastEditors: huangzq
 * @LastEditTime: 2020-11-13 17:01:53
 * @Description:
 */
import { loaderRouters } from '@dustess/duerojs'

const bizRouters = loaderRouters()
const commonRoute = [
  {
    path: '/',
    redirect: '/chat'
  },
  ...bizRouters
]

// TODO: 模块多到一定的规模，可以通过按需构建达到加快开发环境体验的目的
export default commonRoute.concat([])
