/*
 * @Author: lihaitao
 * @Date: 2020-01-09 16:45:47
 * @Last Modified by: lihaitao
 * @Last Modified time: 2020-01-09 20:06:38
 */
import httpCode from './httpCode'
import LocalUser from '@/utils/common/LocalUser'
import $env from '@/plugins/env'

interface HttpCodeError {
  code: number,
  message?: string
}

interface Config {
  url?: string,
  toast: any
}

class HandleHttpCodeError {
  constructor (config: Config) {
    this.config = config
    this.init()
  }

  config: Config

  mapMethods: any

  toastFlag: any

  init () {
    this.mapMethods = {
      401: this.handle401Error,
      404: this.handleCommonError,
      408: this.handleCommonError,
      500: this.handleCommonError,
      10001: this.handleCommonError,
      10002: this.handleCommonError
    }
  }

  public triggerhandleMethods (error: HttpCodeError) {
    const handleMethods = this.mapMethods[error.code]
    if (handleMethods) {
      handleMethods(error, this.config.url)
    } else {
      this.handleOtherError()
    }
  }

  private handle401Error = (error: HttpCodeError) => {
    if (process.env.NODE_ENV === 'development') {
      // 获取研发环境配置
      this.getCurrentConfig().then((res: any) => {
        const { config: { agentId, corpId }, url } = res
        const openUrl = `${url}/qw-manage/#/login?agentId=${agentId}&corpId=${corpId}&feurl=${encodeURIComponent(window.location.href)}`
        window.location.replace(openUrl)
      })
      // 获取环境信息
    } else {
      const msg = httpCode[error.code]
      const user: any = LocalUser.getQWUser()
      const url = `${window.location.origin}${window.location.pathname}#/login?agentId=${user.agentId}&corpId=${user.corpId}`
      this.toastError(msg)
      window.location.replace(url)
    }
  }

  private async getCurrentConfig () {
    const config = await this.getConfig()
    const { VUE_APP_PC_B_BASE_URL, VUE_APP_QW_SCRM_BASE_URL } = $env
    const env = this.getEnv(VUE_APP_PC_B_BASE_URL)
    return new Promise((resolve, reject) => {
      if (env) {
        resolve({
          config: config[env],
          url: VUE_APP_QW_SCRM_BASE_URL
        })
      } else {
        reject(new Error('no env!'))
      }
    })
  }

  private async getConfig () {
    const res = await (await window.fetch('https://yapi.dustess.com/mock/808/login/config', { })).json()
    return res.data
  }

  private getEnv (url: string) {
    return url.split('-')[1].split('.')[0]
  }

  private handleCommonError = (error: HttpCodeError) => {
    const msg = httpCode[error.code]
    this.toastError(msg)
  }

  private handleOtherError = () => {
    this.toastError('未定义的错误！')
  }

  private toastError (message: string) {
    if (!this.toastFlag) {
      this.toastFlag = this.config.toast({
        type: 'danger',
        message,
        onClose: () => {
          this.toastFlag = null
        }
      })
    }
  }
}

export default HandleHttpCodeError
