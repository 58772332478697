import ChatH5 from './index.vue'
import LeaveMessage from './leave-message/index.vue'
export default [
  {
    name: '聊天窗口H5',
    meta: { spm: 'b0404' },

    path: '/chat-h5',
    component: ChatH5
  },
  {
    name: '访客留言',
    meta: { spm: 'b0402' },

    path: '/chat-h5/message',
    component: LeaveMessage
  }
]
