<template>
  <div
    :class="[
      'kf-chat-operate',
      csIsOffline ? 'disable' : ''
    ]"
  >
    <div
      v-for="item in toolbar"
      :key="item"
      class="operate-item"
    >
      <emoji
        v-if="item === toolbarKey['EMOJI']"
        :disabled="csIsOffline"
        @call-select-emoji="handleSelectEmoji"
      />
      <upload-img
        v-if="item === toolbarKey['UPLOAD_IMG']"
        :disabled="csIsOffline"
        @show-upload="showUpload"
        @upload-success="uploadSuccess"
      />
      <qr-code
        v-if="item === toolbarKey['QRCODE']"
        :disabled="csIsOffline"
        :url="qrCodeUrl"
        @call-send-qr-code="sendQrCode"
      />
      <!-- <message
        v-if="item === toolbarKey['MESSAGE']"
        @call-click-message="handleClickMessage"
      /> -->
      <upload-file
        v-if="item === toolbarKey['UPLOAD_FILE']"
        @upload-success="uploadSuccess"
        @show-upload="showUpload"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Emoji from '@components/mk-im/emoji'
import UploadImg from '@components/mk-im/upload-img'
import QrCode from '@components/mk-im/qr-code'
// import Message from '@components/mk-im/message'
import UploadFile from '@components/mk-im/upload-file'
import { toolbarKey } from './define.js'
export default {
  name: 'ChatWindow',
  components: {
    Emoji,
    UploadImg,
    QrCode,
    // Message,
    UploadFile
  },
  props: {
    toolbar: {
      type: Array,
      default: () => ['emoji', 'uploadImg', 'qrCode', 'uploadFile', 'message']
    }
  },
  data () {
    return {
      qwUser: {},
      qrCodeUrl: '',
      toolbarKey
    }
  },
  computed: {
    ...mapState({
      vxReceptionistStatus: state => state.receptionistStatus
    }),
    ...mapGetters({
      csIsOffline: 'csIsOffline'
    })
  },
  created () {
    this.qwUser = JSON.parse(localStorage.getItem('qw-user')) || {}
    this.qrCodeUrl = this.qwUser.qrCode
  },
  methods: {
    // 选中某个表情
    handleSelectEmoji (val) {
      this.$emit('call-select-emoji', val)
    },
    sendQrCode () {
      this.$emit('call-send-qr-code', this.qrCodeUrl)
    },
    uploadSuccess (url) {
      this.$emit('call-upload-success', url)
    },
    handleClickMessage () {
      this.$emit('call-click-message')
    },
    showUpload (val) {
      this.$emit('show-uploadloading', val)
    }
  }
}
</script>

<style lang="scss">
.kf-chat-operate {
  padding: 10px 14px 8px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.disable {
    background: #f7f7f7;
  }

  .operate-item {
    margin-right: 16px;
    line-height: 1;
    cursor: pointer;

    .ds-cloud-upload, .ds-cloud-files-upload{
      line-height: 1;
    }

    .cursor, .el-upload, .kf-message{
      .kf-icon{
        font-size: 20px;
        color: #565E66;
      }
    }
  }

  &.fullScreen{
    .operate-item{
      margin-right: 14px;

      .cursor, .el-upload{
        .kf-icon{
          font-size: 20px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.kf-emoji {
  .emoji-item {
    margin: 3px;
    cursor: pointer;
    text-indent: -999em;
    vertical-align: middle;

    // background: url("~@assets/images/emoji/wx-emoji.png") no-repeat 0 0/1200%;
    // $maxY: 9;
    // $maxX: 10;

    // @for $i from 0 through $maxY {
    //   @for $j from 0 through $maxX {
    //     &.emoji-#{$i*10 + $j} {
    //       background-position: (-$j * 28px) (-$i * 28px);
    //     }
    //   }
    // }
  }
}
</style>
