/*
 * @Author: huangzq
 * @Date: 2020-11-14 16:14:13
 * @LastEditors: huangzq
 * @LastEditTime: 2020-11-14 16:14:24
 * @Description:
 */
import Vue from 'vue'
import Vuex from 'vuex'
import CreateLogger from 'vuex/dist/logger'
import Root from './root/index.js'
import { loaderStores } from '@dustess/duerojs'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  ...Root,
  modules: Object.assign(loaderStores()),
  strict: debug,
  plugins: debug ? [CreateLogger()] : []
})
