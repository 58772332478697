import ajax from '@ajax'

export class BizApi {
  $basePath = '/kf-oss-service/oss'.replace(/\/$/, '');

  constructor (basePath) {
    if (basePath !== undefined) {
      this.$basePath = basePath.replace(/\/$/, '')
    }
  }

  /**
   *
   * @summary 通过oss上传文件
   * @param params Params
   * @returns models.endpointPutFilePost
   */
  endpointPutFilePost = (
    params, config
  ) => {
    const path = '/endpoint/put-file'
    const url = this.$basePath + path
    return ajax.ajax({
      method: 'POST',
      url,
      data: params,
      ...config
    }, path, this.$basePath)
  }
}

export default new BizApi()
