/*
 * @Author: lihaitao
 * @Date: 2019-11-14 10:51:14
 * @Last Modified by: lihaitao
 * @Last Modified time: 2020-01-06 15:02:35
 */
function getSessionId () {
  const user = JSON.parse(window.localStorage.getItem('qw-user'))
  let sid = null
  if (user) {
    sid = user.sid
  }
  return sid
}

function getQWUser () {
  const user = JSON.parse(window.localStorage.getItem('qw-user'))
  if (user) {
    return user
  } else {
    return {}
  }
}

export default {
  getSessionId,
  getQWUser
}
