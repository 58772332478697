import axios, { AxiosError } from 'axios'
import qs from 'qs'
import { Notify } from 'vant'
import $env from '@/plugins/env'
import HandleHttpCodeError from './handleHttpCode/handleHttpCodeError'
import ErrorCodeInterceptors from './handleCode/errorCodeInterceptors'

const { VUE_APP_PC_B_BASE_URL } = $env

const inst = axios.create({
  // baseURL: '', // 本地代理开发启用这个注释
  baseURL: VUE_APP_PC_B_BASE_URL,
  withCredentials: false,
  headers: {}
})

const errorCodeInterceptors = new ErrorCodeInterceptors({
  instance: inst,
  toast: Notify
})

const httpCodeErrorHandler = new HandleHttpCodeError({
  toast: Notify
})

inst.interceptors.response = errorCodeInterceptors.getInterceptorsInstance()

// @cc: 检测 axios 响应状态
function onStatusError (error: AxiosError | Error) {
  const err =
    'response' in error && error.response
      ? {
        code: error.response.status,
        message: error.response.data.msg
      }
      : navigator.onLine ? { code: 10001, message: error.message } : { code: 10002, message: '网络错误，请检查网络是否正常！' }

  httpCodeErrorHandler.triggerhandleMethods(err)
  return err
}

export type AjaxPromise<R> = Promise<R>

export interface ExtraFetchParams {
  extra?: any
}

export interface WrappedFetchParams extends ExtraFetchParams {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH' | 'HEAD'
  url: string
  data?: any // post json
  form?: any // post form
  query?: any
  header?: any
  path?: any
}

export class WrappedFetch {
  /**
   * @description ajax 方法
   */
  public ajax (
    { method, url, data, form, query, header, extra }: WrappedFetchParams,
    path?: string,
    basePath?: string
  ) {
    const user: any = window.localStorage.getItem('qw-user')
    let config = {
      ...extra,
      method: method.toLocaleLowerCase(),
      headers: {
        ...header,
        Authorization: JSON.parse(user) ? JSON.parse(user).sid : ''
      }
    }
    // json
    if (data) {
      config = {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          ...config.headers
        },
        data
      }
    }
    // form
    if (form) {
      if (config.headers['Content-Type'] === 'multipart/form-data') {
        const formData = new FormData()
        for (const key in form) {
          formData.append(key, form[key])
        }
        form = formData
      } else {
        form = qs.stringify(form)
      }

      config = {
        ...config,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          ...config.headers
        },
        data: form
      }
    }
    return inst
      .request({ ...config, url, params: query })
      .then(res => res.data)
      .catch(onStatusError)
  }

  /**
   * @description 接口传参校验
   */
  public check<V> (value: V, name: string) {
    if (value === null || value === undefined) {
      const msg = `[ERROR PARAMS]: ${name} can't be null or undefined`
      // 非生产环境，直接抛出错误
      if (process.env.NODE_ENV === 'development') {
        throw Error(msg)
      }
    }
  }
}

export default new WrappedFetch()
