function getApis () {
  const modules = require.context('@api', true, /index\.(ts|js)$/)
  let apis = []
  modules.keys().forEach(key => {
    if (key.match('/services/index')) {
      const keySplit = key.split('/')
      const APISNAME = keySplit[1].replace(/-/g, '').toLocaleUpperCase()
      apis.push({
        YAPINAME: keySplit[1],
        APISNAME,
        APIS: modules(key).APIS
      })
    }
  })
  return apis
}

export default function exportdefault () {
  const apis = getApis()
  // eslint-disable-next-line
  console.log(`当前项目共有 ${apis.length} 个接口服务，如下所示：`)

  apis.forEach(module => {
    window[module.APISNAME] = module.APIS

    // eslint-disable-next-line
    console.log(`【${module.YAPINAME}】 对应为：【${module.APISNAME}】`)
  })

  // 专门新增一个BIZAPIS变量，用于处理mk-upload-oss组件里面对BIZAPIS的调用，避免报错
  // window.BIZAPIS = window.MKBIZWEB
}
