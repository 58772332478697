import Vue from 'vue'
import Router from 'vue-router'
import Routes from './routes'
Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: Routes
})

router.beforeEach((to, from, next) => {
  // 此方法由 monitor sdk 注入，sdk 需要前置加载执行
  window.Monitor && window.Monitor.switchPage(to, from)

  next()
})

router.afterEach((to, from) => {
  // 此方法由 monitor sdk 注入，sdk 需要前置加载执行
  window.Monitor && window.Monitor.switchPageDone(to, from)
})
export default router
