<template>
  <div class="message-window-bottom">
    <div
      class="message-window-bottom-submit"
      :style="style"
      :test-id="'#112233'"
      @click="$emit('handleSubmit')"
    >
      提交留言
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageWindowBottom',
  props: {
    theme: {
      type: String,
      default: ''
    }
  },
  computed: {
    style () {
      return {
        '--bg-color': this.theme
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-window-bottom{
  background-color: #fff;
  padding: 15px 55px;
  box-shadow: 0 1px 0 0 #f5f5f5;
  border-top:1px solid #f5f5f5;

  &-submit{
    width: 100%;
    height: 42px;
    font-size: 14px;
    line-height: 42px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    background-color: var(--bg-color);
  }
}
</style>
