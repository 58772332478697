<template>
  <el-dialog
    class="dialog-message"
    fullscreen
    :visible.sync="visible"
    :modal="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <MessageWindow
      :theme="theme"
      @close="visible = false"
    />
  </el-dialog>
</template>

<script>
import MessageWindow from '@components/message-window'
export default {
  name: 'DialogMessage',
  components: {
    MessageWindow
  },
  props: {
    theme: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-message{
  position: absolute;

  ::v-deep .el-dialog__header{
    display: none;
  }

  ::v-deep .el-dialog__body{
    height: 100%;
    padding: 0;
  }
}
</style>
