<template>
  <div class="kf-upload-file">
    <cloud-files-upload
      ref="uploadFile"
      :disabled="disabled"
      :token-api="$DSCLOUD.getTokenApi()"
      :upload-types="['image']"
      :accept="getImageAccept()"
      :accept-type="getAcceptType()"
      @on-upload="handleUpload"
    >
      <div>
        <slot>
          <mk-iconfont
            icon-class="iconfont-2492785 duero-icon-2492785-tupian01 icon-normal"
            :class="disabled?'not-allowed':'cursor'"
          />
        </slot>
      </div>
    </cloud-files-upload>
  </div>
</template>

<script>
import CloudFilesUpload from '@components/cloud-files-upload'
import { FILE_ACCEPT_TYPE } from '@utils/enums/im.js'
export default {
  name: 'UploadImg',
  components: { CloudFilesUpload },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    acceptType: {
      type: Array,
      default: () => ['.jpg', '.png', '.jpeg']
    }
  },
  methods: {
    getAccept () {
      let acceptTypes = this.acceptType.map(item => FILE_ACCEPT_TYPE[item])
      return [...new Set(acceptTypes)].join(',')
    },
    getAcceptType () {
      return this.acceptType.join(',')
    },
    getImageAccept () {
      return this.$route.path.includes('/chat-h5') ? 'image/*' : this.getAcceptType()
    },
    async handleUpload (res) {
      res.type === 'before' && this.$emit('show-upload', true)
      if (res.type === 'success') {
        this.$emit('show-upload', false)
        const { linkUrl } = res.result['data']
        let fileInfo = { file: res.file, linkUrl }
        this.$refs['uploadFile'].$children[0].clearFiles()
        this.$emit('upload-success', fileInfo)
      }
      if (res.type === 'fail') {
        this.$emit('show-upload', false)
        this.$notify({ type: 'danger', message: '发送失败，请重新发送' })
        this.$refs['uploadFile'].$children[0].clearFiles()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.kf-upload-file {
  ::v-deep .el-upload {
    &:focus {
      color: inherit;
    }
  }
}
</style>
