import Vue from 'vue'
import { jsonp } from './jsonp/jsonp.js'
import dayjs from 'dayjs'
import md5 from 'blueimp-md5'
const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export function loadImage (src) {
  let p = new Promise(function (resolve, reject) {
    let img = new Image()
    img.onload = function () {
      resolve(img)
    }
    img.onerror = function () {
      reject(src)
    }
    img.src = src
  })
  return p
}

export function getType (obj) {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}

export function getUploadSign () {
  const token = 'b4e9ce6bc4144c6a92d6a2ce9516321c'
  return md5(token + dayjs().format('YYYYMMDD') + '')
}

export function getUrlParams () {
  let params = {}
  const urlQueryString = window.location.href.split('?')[1]
  if (urlQueryString) {
    const querys = urlQueryString.split('&')
    for (let query of querys) {
      const queryEntries = query.split('=')
      params[queryEntries[0]] = queryEntries[1] ? decodeURIComponent(queryEntries[1]) : ''
    }
  }
  return params
}

export function chatTime (timestamp = '') {
  if (timestamp === '') {
    return ''
  }
  let now = dayjs()
  let time = dayjs(timestamp)
  if (time.isToday()) {
    return time.format('HH:mm')
  } else if (time.format('YYYY') === now.format('YYYY')) {
    return time.format('MM.DD HH:mm')
  } else {
    return time.format('YYYY.MM.DD HH:mm')
  }
}

/*
 *
 * 聊天时间显示规则
 *
 */
export function transferWetChatTime (unixStamp) { // unixStamp 精确到微秒
  var _todayObj = new Date()
  var _todayDate = {
    y: _todayObj.getFullYear(),
    m: (_todayObj.getMonth() + 1 < 10 ? '0' + (_todayObj.getMonth() - -1) : (_todayObj.getMonth() - -1)),
    d: (_todayObj.getDate() < 10 ? '0' + _todayObj.getDate() : _todayObj.getDate())
  }

  var _todayStamp = Date.parse(_todayDate.y + '/' + _todayDate.m + '/' + _todayDate.d + ' 00:00:00')

  var stamp = []
  stamp[0] = _todayStamp + 86400000
  stamp[1] = _todayStamp
  stamp[2] = _todayStamp - 86400000
  stamp[3] = _todayStamp - 172800000

  stamp[4] = _todayStamp - 518400000 // 7天

  stamp[5] = _todayStamp - 31536000000 // 365天

  var _compareObj = new Date()
  _compareObj.setTime(unixStamp)

  var _returnStr

  if (unixStamp >= stamp[1] && unixStamp < stamp[0]) {
    _returnStr = _compareObj.getHours() + ':' + (_compareObj.getMinutes() < 10 ? '0' + _compareObj.getMinutes()
      : _compareObj.getMinutes())
  } else if (unixStamp >= stamp[2] && unixStamp < stamp[1]) {
    var yesterdayText = '昨天'
    _returnStr = yesterdayText + ' ' + _compareObj.getHours() + ':' +
      (_compareObj.getMinutes() < 10 ? '0' + _compareObj.getMinutes() : _compareObj.getMinutes())
  } else if (unixStamp >= stamp[4] && unixStamp < stamp[2]) { // 7天内
    var daynames = ['天', '一', '二', '三', '四', '五', '六']
    var dathStr = '星期' + daynames[_compareObj.getDay()]

    var SundayText = '星期天'
    var MondayText = '星期一'
    var TuesdayText = '星期二'
    var WednesdayText = '星期三'
    var ThursdayText = '星期四'
    var FridayText = '星期五'
    var SaturdayText = '星期六'

    var dathStr2

    switch (dathStr) {
      case '星期天':
        dathStr2 = SundayText
        break
      case '星期一':
        dathStr2 = MondayText
        break
      case '星期二':
        dathStr2 = TuesdayText
        break
      case '星期三':
        dathStr2 = WednesdayText
        break
      case '星期四':
        dathStr2 = ThursdayText
        break
      case '星期五':
        dathStr2 = FridayText
        break
      case '星期六':
        dathStr2 = SaturdayText
        break
      default:
        dathStr2 = dathStr
        break
    }

    _returnStr = dathStr2 + ' ' + _compareObj.getHours() + ':' +
      (_compareObj.getMinutes() < 10 ? '0' + _compareObj.getMinutes() : _compareObj.getMinutes())
  } else if (unixStamp >= stamp[5] && unixStamp < stamp[4]) { // 365天内
    _returnStr = _compareObj.getFullYear() + '-' + (_compareObj.getMonth() - (-1)) + '-' + _compareObj.getDate() +
      ' ' +
      _compareObj.getHours() + ':' + (_compareObj.getMinutes() < 10 ? '0' + _compareObj.getMinutes() : _compareObj.getMinutes())
  } else {
    _returnStr = _compareObj.getFullYear() + '-' + (_compareObj.getMonth() - (-1)) +
      '-' + _compareObj.getDate() + ' ' + _compareObj.getHours() + ':' +
      (_compareObj.getMinutes() < 10 ? '0' + _compareObj.getMinutes() : _compareObj.getMinutes())
  }
  return _returnStr
}

// 求次幂
function pow1024 (num) {
  return Math.pow(1024, num)
}

export const EventBus = new Vue()

const apiKey = process.env.NODE_ENV === 'development' ? 'PECBZ-PXKCQ-4N65N-GF373-TEFVV-SLFGN' : 'SKDBZ-TLLCD-TNY4S-PVRAD-TMPIF-YBBLK'
export const getIPLocation = async () => {
  return await jsonp(`https://apis.map.qq.com/ws/location/v1/ip?key=${apiKey}&output=jsonp`)
}

/**
 * 文件大小 字节转换单位
 * @param size
 * @returns {string|*}
 */
export const filterSize = (size) => {
  if (!size) return ''
  if (size < pow1024(1)) return size + ' B'
  if (size < pow1024(2)) return (size / pow1024(1)).toFixed(2) + ' K'
  if (size < pow1024(3)) return (size / pow1024(2)).toFixed(2) + ' M'
  if (size < pow1024(4)) return (size / pow1024(3)).toFixed(2) + ' G'
  return (size / pow1024(4)).toFixed(2) + ' T'
}
