import MkIconfont from './index.vue'
const customCache = new Set()

MkIconfont.install = function (Vue, options) {
  const { scriptUrl } = options

  scriptUrl.forEach(url => {
    const script = document.createElement('link')
    script.setAttribute('rel', 'stylesheet')
    script.setAttribute('type', 'text/css')
    script.setAttribute('href', url)
    // script.setAttribute('data-namespace', url)
    customCache.add(url)
    document.head.appendChild(script)
  })
  Vue.component(MkIconfont.name, MkIconfont)
}

export default MkIconfont
