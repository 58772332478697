import Vue from 'vue'
import { getTokenApi, getDefaultPlaceholderImage } from './util.js'

export default () => {
  // 注册全局使用的参数获取方法
  Vue.prototype.$DSCLOUD = {
    getTokenApi,
    getDefaultPlaceholderImage
  }
}
