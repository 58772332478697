<template>
  <div class="chat-window-wap">
    <chat-window-top :title="chatInfo.kfName || '在线客服'">
      <template #operate>
        <mk-iconfont
          style="font-size:18px;"
          :icon-class="audioOpen ? 'iconfont-2560775 duero-icon-2560775-shengyin-dakai' : 'iconfont-2560775 duero-icon-2560775-shengyin-guanbi'"
          @click="switchSound"
        ></mk-iconfont>
      </template>
    </chat-window-top>
    <chat-window-content
      ref="chatContent"
      style="flex:1;background-color:#F6F8FA;"
    ></chat-window-content>
    <chat-window-bottom @contentScrollToBottom="contentScrollToBottom" />
  </div>
</template>

<script>
import ChatWindowTop from './chat-window-top'
import ChatWindowContent from '../chat-window/chat-window-content'
import ChatWindowBottom from './chat-window-bottom'
import { EventBus } from '@utils/common.js'
import Sound from '@/assets/audio/sound.mp3'
export default {
  name: 'ChatWindowWap',
  components: {
    ChatWindowTop,
    ChatWindowContent,
    ChatWindowBottom
  },
  data () {
    return {
      audioOpen: true
    }
  },
  computed: {
    chatInfo () {
      return this.$store.state.chatInfo
    },
    chatConfig () {
      return this.$store.state.chatConfig
    }
  },
  created () {
    EventBus.$on('newMessage', this.playAudio)
  },
  beforeDestroy () {
    EventBus.$off('newMessage', this.playAudio)
  },
  methods: {
    contentScrollToBottom () {
      this.$refs.chatContent.updateScrollTop()
    },
    switchSound () {
      this.audioOpen = !this.audioOpen
      if (!this.audioOpen) {
        const audios = document.querySelectorAll('.chat-message-audio')
        for (let audio of audios) {
          document.body.removeChild(audio)
        }
      }
      const spmSwitchSound = 'c0405.d0421'/** data-spm-desc: 访客H5聊天框-开启/关闭声音 **/
      window.Monitor?.sendSPM(spmSwitchSound)
    },
    playAudio () {
      if (this.audioOpen) {
        let audio = document.createElement('audio')
        audio.src = Sound
        audio.autoplay = true
        audio.className = 'chat-message-audio'
        audio.style.display = 'none'
        audio.addEventListener('ended', () => {
          document.body.removeChild(audio)
        })
        document.body.appendChild(audio)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-window-wap{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
