<template>
  <div>
    <kf-test title="Web Component"></kf-test>
  </div>
</template>

<script>

export default {
  name: 'TestPage',
  data () {
    return {}
  }
}
</script>
