<template>
  <div
    ref="refQrCode"
    class="qr-code"
  >
    <div
      :class="disabled?'not-allowed':'cursor'"
      @click.stop="onShow"
    >
      <mk-iconfont
        icon-class="iconfont-2492785 duero-icon-2492785-kf_qiweierweima icon-normal"
        :class="show ? 'icon-active' : ''"
      />
    </div>
    <div
      v-show="show"
      class="panel"
    >
      <div
        class="qr-code-url"
        :style="{'background': `url(${url}) no-repeat center/100%`}"
      >
      </div>
      <p
        class="text cursor"
        @click="onSendQrCode"
      >
        <i class="el-icon-upload2"></i>
        发送企微二维码
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'QrCode',
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
    ...mapState({
      vxReceptionistStatus: state => state.receptionistStatus
    })
  },
  watch: {
    disabled: {
      handler: function (val) {
        if (val) {
          this.show = false
        }
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handlePanel, false)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handlePanel, false)
  },
  methods: {
    onShow () {
      if (this.vxReceptionistStatus === 'offline') { return }
      this.toggleShow()
    },
    toggleShow (val) {
      this.show = val || !this.show
    },
    onSendQrCode () {
      this.toggleShow(false)
      this.$emit('call-send-qr-code')
    },
    handlePanel (e) {
      const ref = this.$refs.refQrCode
      if (!(ref && ref.contains(e.target))) {
        this.show = false
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.qr-code {
  position: relative;

  .icon-active {
    color: #009cff;
  }

  .panel {
    position: absolute;
    top: -180px;
    padding: 10px;
    width: 118px;
    height: 145px;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-size: 0;

    .qr-code-url {
      width: 118px;
      height: 117px;
    }

    .text {
      margin-top: 10px;
      line-height: 18px;
      font-weight: 400;
      font-size: 13px;
      color: #009cff;
    }
  }
}
</style>
